export const cardIcons = {
  visa: require("../images/CardIcons/visa.png"),
  mastercard: require("../images/CardIcons/mastercard.png"),
  "american express": require("../images/CardIcons/amex.png"),
  discover: require("../images/CardIcons/discover.png"),
  jcb: require("../images/CardIcons/jcb.png"),
  "diners club": require("../images/CardIcons/dinersclub.png"),
  maestro: require("../images/CardIcons/maestro.png"),
  mada: require("../images/CardIcons/mada.png"),
};
