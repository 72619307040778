import { SET_SLOTS_DATA, SET_PROFILE_DATA } from './types';

export const setSlotsData = data => {
  return {
    type: SET_SLOTS_DATA,
    payload: data
  }
}

export const setProfileData = data => {
  return {
    type: SET_PROFILE_DATA,
    payload: data
  }
}