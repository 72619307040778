const initialState = {
    slotsData: {},
    profileData: {}
  };

  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_SLOTS_DATA': {
        return {
          ...state,
          slotsData: action.payload,
        };
      }
      case 'SET_PROFILE_DATA': {
        return {
          ...state,
          profileData: action.payload,
        };
      }
      default: {
        return state;
      }
    }
  };
  // Exports
  export default reducer;