import React from "react";
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";



const ErrorPage = (props) => {
  return (
    <div>
    <Helmet>
    <title>404 - Page Not Found </title>    
    <meta property="title" content="404 - Page Not Found" />
    <meta name="description" content="404 - Page Not Found" />
    </Helmet>
    <div className="content-container" style={{ padding: 0 }}>
    <div className="services-container" style={{ padding: 0 }}>
        <h1>404 - Page Not Found</h1>
        <p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
    </div>
    </div>
    <div>
    <Link to="/home">
              <div className="content-container" style={{ padding: 0 }}>
                <div className="services-container" style={{ padding: 0 }}>
                  <div className="book-btn2" onClick={props.setBookOrder}>
                    Home Page
                  </div>
                </div>
              </div>
            </Link>
      </div>
      </div>
  );
};

export default ErrorPage;
