import React from "react";
import backgroundImg from "../images/page_background_sec_web.svg";
import backgroundMobileImg from "../images/page_background_sec_mobile2.svg";
import largeBackgroundImg from "../images/page_background_sec_web_large.svg";

export default class Privacy extends React.Component {
  render() {
    return (
      <div>
        <div
          className="header-image"
          style={{ position: "absolute", top: "0", zIndex: "-1" }}
        >
          <img
            alt=""
            src={
              window.innerWidth > 1440
                ? largeBackgroundImg
                : window.innerWidth > 768
                ? backgroundImg
                : backgroundMobileImg
            }
          />
        </div>
        <div>
          <div className="heading">Privacy Policy</div>
        </div>
        <div className="content-container content-container-privacy">
          We are committed to safeguarding the privacy of our website visitors;
          this policy sets out how we will treat your personal information. Our
          website uses cookies. By using our website and agreeing to this
          policy, you consent to our use of cookies in accordance with the terms
          of this policy. What information do we collect? We may collect, store
          and use the following kinds of personal information:
          <ul>
            <li>
              information about your computer and about your visits to and use
              of this website (including your IP address, geographical location,
              browser type and version, operating system, referral source,
              length of visit, page views and website navigation);
            </li>
            <li>
              information relating to any transactions carried out between you
              and us on or in relation to this website;
            </li>
            <li>
              information that you provide to us for the purpose of registering
              with us (including your name, address and email address);
            </li>
            <li>
              information that you provide to us for the purpose of subscribing
              to our website services, SMS notifications and/or newsletters
              (including your name and email address;
            </li>
            <li>any other information that you choose to send to us;</li>
          </ul>
          <div>
            <h6>1. Cookies</h6>
            <p>
              A cookie is a file containing an identifier (a string of letters
              and numbers) that is sent by a web server to a web browser and is
              stored by the browser. The identifier is then sent back to the
              server each time the browser requests a page from the server. This
              enables the web server to identify and track the web browser. We
              may use both “session” cookie and “persistent” cookies on the
              website. Session cookies will be deleted from your computer when
              you close your browser. Persistent cookies will remain stored on
              your computer until deleted, or until they reach a specified
              expiry date. We will use the session cookies to: keep track of you
              whilst you navigate the website; keep track of your bookings;
              prevent fraud and increase website security; and [other uses]. We
              will use the persistent cookies to: enable our website to
              recognize you when you visit; keep track of your preferences in
              relation to your use of our website; and other uses.
              <br />
              We use Google Analytics to analyze the use of this website. Google
              Analytics generates statistical and other information about
              website use by means of cookies, which are stored on users’
              computers. The information generated relating to our website is
              used to create reports about the use of the website. Google will
              store this information. Google’s privacy policy is available at:
              http://www.google.com/privacypolicy.html. Our payment services
              providers may also send you cookies.
            </p>
          </div>
          <div>
            <h6>2. Using Your Personal Information</h6>
            <p>
              Where you submit personal information for publication on our
              website, we will publish and otherwise use that information in
              accordance with the licence you grant to us. We will not, without
              your express consent, provide your personal information to any
              third parties for the purpose of direct marketing.
            </p>
          </div>
          <div>
            <h6>3. Disclosures</h6>
            <p>
              We may disclose your personal information to any of our employees,
              officers, agents, suppliers or subcontractors insofar as
              reasonably necessary for the purposes set out in this privacy
              policy. In addition, we may disclose your personal information:
            </p>
            <ul>
              <li>to the extent that we are required to do so by law;</li>
              <li>
                in connection with any ongoing or prospective legal proceedings;
              </li>
              <li>
                in order to establish, exercise or defend our legal rights
                (including providing information to others for the purposes of
                fraud prevention and reducing credit risk);
              </li>
              <li>
                to any person who we reasonably believe may apply to a court or
                other competent authority for disclosure of that personal
                information where, in our reasonable opinion, such court or
                authority would be reasonably likely to order disclosure of that
                personal information.
              </li>
            </ul>
          </div>
          <div>
            <h6>4. International Data Transfers</h6>
            <p>
              Information that we collect may be stored and processed in and
              transferred between any of the countries in which we operate in
              order to enable us to use the information in accordance with this
              privacy policy. In addition, personal information that you submit
              for publication on the website may be published on the internet
              and may be available, via the internet, around the world. We
              cannot prevent the use or misuse of such information by others.
              You expressly agree to such transfers of personal information.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
