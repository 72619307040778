import React from "react";
import { connect } from "react-redux";
import { setSlotsData, setProfileData } from "../actions";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

class OrdersHistory extends React.Component {
  state = {
    orders: {},
    tab: "active",
  };

  componentDidMount() {
    document.body.style.backgroundColor = "rgb(249, 249, 251)";

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("userToken")}`
    );
    let requestOptions2 = {
      // credentials: 'include',
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch("https://api.washon.ae/profile/get", requestOptions2)
      .then((response) => {
        if (response.status === 200) return response.json();
        else return false;
      })
      .then((result) => {
        if (result) {
          this.props.setProfileData(result.data);
        }
      })
      .catch((error) => console.error(error));
    fetch("https://api.washon.ae/booking/user", requestOptions2)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else {
          return false;
        }
      })
      .then((result) => {
        if (result) {
          this.setState({ orders: result.data ?? {} });
        }
      })
      .catch((error) => console.error(error));
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.profileData !== nextProps.profileData) {
      const { ref_code } = nextProps.profileData;
      this.setState({
        ref_code,
      });
    }
  }

  render() {
    return (
      <div className="profile-container">
        <h3>Hello {this.props.profileData.name || "User"}</h3>
        <div className="sub-container">
          <div className="promo-code-container">
            <h3>Orders</h3>
            <div
              style={{
                width: "100%",
                height: "1px",
                background: "rgb(236, 237, 242)",
                marginTop: "14px",
              }}
            ></div>
            <div style={{ display: "flex", padding: "36px 0" }}>
              <div
                className={`tab ${
                  this.state.tab === "active" ? "tabactive" : ""
                }`}
                onClick={() => {
                  this.setState({ tab: "active" });
                }}
              >
                Active
              </div>
              <div
                className={`tab ${
                  this.state.tab === "completed" ? "tabactive" : ""
                }`}
                style={{ marginLeft: "24px" }}
                onClick={() => {
                  this.setState({ tab: "completed" });
                }}
              >
                Completed
              </div>
            </div>
            {window.innerWidth > 768 ? (
              <TableContainer component={Paper}>
                <Table aria-label="customized table">
                  <TableHead>
                    <TableCell
                      style={{
                        fontSize: "14px",
                        background: "rgb(245, 245, 245)",
                        color: "rgb(143, 152, 179)",
                      }}
                    >
                      Order Id{" "}
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "14px",
                        background: "rgb(245, 245, 245)",
                        color: "rgb(143, 152, 179)",
                      }}
                    >
                      Pick Up
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "14px",
                        background: "rgb(245, 245, 245)",
                        color: "rgb(143, 152, 179)",
                      }}
                    >
                      Price{" "}
                    </TableCell>
                    <TableCell
                      style={{
                        fontSize: "14px",
                        background: "rgb(245, 245, 245)",
                        color: "rgb(143, 152, 179)",
                      }}
                    >
                      Status
                    </TableCell>
                    {this.state.tab === "active" && (
                      <TableCell
                        style={{
                          fontSize: "14px",
                          background: "rgb(245, 245, 245)",
                          color: "rgb(143, 152, 179)",
                        }}
                      >
                        Payment Status{" "}
                      </TableCell>
                    )}
                  </TableHead>
                  <TableBody>
                    {this.state.orders.length &&
                      this.state.orders[this.state.tab] &&
                      this.state.orders[this.state.tab].map((row) => (
                        <TableRow key={row.id}>
                          <TableCell
                            style={{
                              fontSize: "14px",
                              color: "rgb(30, 30, 93)",
                              padding: "24px 16px",
                            }}
                          >
                            {row.id}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "14px",
                              color: "rgb(30, 30, 93)",
                              padding: "24px 16px",
                            }}
                          >
                            {row.booking_time}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "14px",
                              color: "rgb(30, 30, 93)",
                              padding: "24px 16px",
                            }}
                          >
                            {parseInt(row.booking_price)
                              ? `${row.booking_price} AED`
                              : row.booking_price}
                          </TableCell>
                          <TableCell
                            style={{
                              fontSize: "14px",
                              color: "rgb(30, 30, 93)",
                              padding: "24px 16px",
                            }}
                          >
                            {row.status}
                          </TableCell>
                          {this.state.tab === "active" && (
                            <TableCell
                              style={{
                                fontSize: "14px",
                                color: "rgb(30, 30, 93)",
                                padding: "24px 16px",
                              }}
                            >
                              {row.paid}
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : (
              <React.Fragment>
                {this.state.orders[this.state.tab] &&
                  this.state.orders[this.state.tab].map((row) => (
                    <div className="order-cont">
                      <span style={{ color: "rgb(30, 30, 93)" }}>
                        {row.booking_time}
                      </span>
                      <div
                        style={{
                          padding: "8px 0",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span style={{ color: "rgb(168, 176, 201)" }}>
                          ORDER ID #{row.id}
                        </span>
                        <span
                          style={{
                            color: "rgb(30, 30, 93)",
                            fontWeight: "bolder",
                          }}
                        >
                          {parseInt(row.booking_price)
                            ? `${row.booking_price} AED`
                            : row.booking_price}
                        </span>
                      </div>
                      <span
                        style={{
                          color: "rgb(30, 30, 93)",
                          fontWeight: "bolder",
                        }}
                      >
                        {row.status}
                      </span>
                    </div>
                  ))}
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    slotsData: state.slotsData,
    profileData: state.profileData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSlotsData: (data) => {
      dispatch(setSlotsData(data));
    },
    setProfileData: (data) => {
      dispatch(setProfileData(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrdersHistory);
