export const schemafiles = 
{
    "@context": "https://schema.org",
    "@type": "DryCleaningOrLaundry",
    "name": "WashOn Laundry: Premium laundry service in Dubai. Laundry near you",
    "description": "WashOn Laundry gives you high quality laundry, dry cleaning, shoe cleaning, carpet cleaning and steam ironing service near you in Dubai. Our free laundry pickup services collect, clean & deliver your cloths in 24 hours.",
    "image": "https://washon-image.s3.ap-south-1.amazonaws.com/site-image/washon-white-bg.png",
    "@id": "Washon Laundry Home Page",
    "url": "https://www.washon.ae",
    "telephone": "+971-506856502",
    "address": {
      "@type": "PostalAddress",
      "streetAddress": "Downtown Dubai",
      "addressLocality": "Dubai",
      "postalCode": "0000",
      "addressCountry": "AE"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 25.1949849,
      "longitude": 55.2784141
    },
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ],
      "opens": "08:00",
      "closes": "21:00"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.83",
      "ratingCount": "879"
    },
    "department": [{
      "@type": "DryCleaningOrLaundry",
      "name": "WashOn Shoe Cleaning: Premium Shoe cleaning in Dubai. Shoe cleaning service near you",
    "description": "WashOn Laundry gives you high quality shoe cleaning service near you in Dubai. From sneakers to leather boots, we bring new life to your shoes with meticulous care and attention to detail.",
      "image": "https://washon-image.s3.ap-south-1.amazonaws.com/site-image/shoe-cleaning-washon.png",
      "telephone": "+971-506856502",
        "address": {
      "@type": "PostalAddress",
      "streetAddress": "Downtown Dubai",
      "addressLocality": "Dubai",
      "postalCode": "0000",
      "addressCountry": "AE"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 25.1949849,
      "longitude": 55.2784141
    },
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ],
      "opens": "08:00",
      "closes": "21:00"
    } 
    },{
      "@type": "DryCleaningOrLaundry",
      "name": "WashOn Carpet Cleaning: Premium Carpet cleaning in Dubai. Carpet cleaning service near you",
    "description": "WashOn Laundry gives you high quality carpet cleaning service near you in Dubai.Restore your carpets to their former glory with our professional cleaning service.",
      "image": "https://washon-image.s3.ap-south-1.amazonaws.com/site-image/carpet-cleaning-washon.png",
      "telephone": "+971-506856502",
        "address": {
      "@type": "PostalAddress",
      "streetAddress": "Downtown Dubai",
      "addressLocality": "Dubai",
      "postalCode": "0000",
      "addressCountry": "AE"
    },
    "geo": {
      "@type": "GeoCoordinates",
      "latitude": 25.1949849,
      "longitude": 55.2784141
    },
    "openingHoursSpecification": {
      "@type": "OpeningHoursSpecification",
      "dayOfWeek": [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ],
      "opens": "08:00",
      "closes": "21:00"
    }
    }]
  };