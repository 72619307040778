import React from "react";
import "../css/floatingbutton.css";

const FloatingButton = (props) => {
  return (
    <div className={`floating-btn ${props.href === "/pricing" && "whiteClr"}`}>
      <a href={props.href}>
        <img
          title={props.title}
          alt="floating_icon"
          src={props.icon}
          style={{
            height: props.href === "/pricing" ? "60px" : "30px",
            width: props.href === "/pricing" ? "60px" : "30px",
          }}
        />
      </a>
    </div>
  );
};

export default FloatingButton;
