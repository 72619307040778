import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { CardNumber, Cvv, ExpiryDate, Frames } from "frames-react";
import { cardIcons } from "../utils/CardTypes";
import ExpandDownIcon from "../images/angle-down-solid.png";
import AddIcon from "../images/add-icon.png";
import Modal from "react-modal";
import "../css/payment.css";
import { Alert, AlertTitle } from "@material-ui/lab";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Close from "@material-ui/icons/Close";

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.grey[400],
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const StyledButton = withStyles({
  root: {
    width: 139,
    height: 48,
    marginRight: 18,
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);

export default function Payment(props) {
  const [state, setState] = React.useState({
    checkedA: true,
    paymentMethod: "Cash",
  });

  const [savingCard, setSavingCard] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showAddCard, setShowAddCard] = useState(true);
  const [helperText, setHelperText] = useState("");
  const [cardError, setCardError] = useState(false);
  const [cardSaved, setCardSaved] = useState(false);

  const [cardholder, setCardholder] = useState({
    name: "",
  });

  const toggleSwitch = (type, e) => {
    if (type === "AddCard") {
      setState({ paymentMethod: type });
      if (cardList.length === 0) {
        setIsModalVisible(true);
      }
    } else {
      setState({ paymentMethod: "Cash" });
      props.setPaymentMode("Cash", {});
    }
  };
  const [promoCode, setPromoCode] = useState("");
  const [error, setError] = useState(false);
  const [cardList, setCardList] = useState([]);
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);

  const applyPromoCode = async () => {
    try {
      if (promoCode === "") return;

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Accept", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("userToken")}`
      );
      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      const response = await fetch(
        `https://api.washon.ae/promo/validate?code=${promoCode}`,
        requestOptions
      );

      const resData = await response.json();
      if (resData) {
        if (resData.data.code === 400) {
          setError(true);
        } else {
          setError(false);
        }
        setHelperText(resData.data.message);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const setPaymentMode = () => {
    props.setPaymentMode(
      state.paymentMethod === "AddCard"
        ? "CreditCard"
        : state.paymentMethod === "CreditCard"
        ? "CreditCard"
        : "Cash",
      cardList[selectedCardIndex],
      !error ? promoCode : null
    );
    props.handleNext();
  };

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("userToken")}`
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`https://api.washon.ae/payment/get`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setCardList(result.data);
      })
      .catch((error) => console.error("error", error));
  }, []);

  const saveCard = async () => {
    setSavingCard(true);
    if (Frames.isCardValid()) {
      try {
        const res = await Frames.submitCard();
        try {
          // Environment Config
          const response = await fetch(
            `https://api.washon.ae/payment/prod/add`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              },
              redirect: "follow",
              body: JSON.stringify(res),
            }
          );
          const result = await response.json();
          if (result && result.data) {
            if (result.data.approved) {
              setCardList(result.data.payments);
              setSelectedCardIndex(0);
              setState({ paymentMethod: "AddCard" });
              setCardSaved(true);
              setIsModalVisible(false);
            } else {
              setCardError(true);
            }
          }
        } catch (e) {
          alert("Something went wrong. Card details could not be added.", e);
          setCardError(true);
        }
      } catch (e) {
        alert("Something went wrong. Card details could not be added.");
        setCardError(true);
      }
    } else {
      // alert("Card details Invalid");
      setCardError(true);
    }
    setSavingCard(false);
  };

  const onSelectCard = (i) => {
    setSelectedCardIndex(i);
    props.setPaymentMode(
      state.paymentMethod,
      cardList[i],
      !error ? promoCode : null
    );
    setIsModalVisible(false);
  };

  return (
    <div className="date-time-container">
      <div>
        <h4>Payment (How would you like to pay for our service)</h4>
        <hr />
        <div
          style={{ margin: window.innerWidth > 768 ? "30px 20%" : "30px 5%" }}
        >
          <h6 style={{ fontSize: "18px", color: "rgb(30, 30, 93)" }}>
            Choose Payment Method
          </h6>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "20px 0px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ fontSize: "16px", color: "rgb(30, 30, 93)" }}>
                Pay via Cash
              </span>
              <span style={{ fontSize: "16px", color: "rgb(168, 176, 201)" }}>
                at the time of delivery{" "}
              </span>
            </div>
            <IOSSwitch
              checked={state.paymentMethod === "Cash"}
              onChange={(e) => toggleSwitch("Cash", e)}
              value="checkedA"
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <span style={{ fontSize: "16px", color: "rgb(30, 30, 93)" }}>
                Pay via Credit Card
              </span>
              <span style={{ fontSize: "16px", color: "rgb(168, 176, 201)" }}>
                Would be charged before delivery
              </span>
            </div>
            <IOSSwitch
              checked={state.paymentMethod === "AddCard"}
              onChange={(e) => toggleSwitch("AddCard", e)}
              value="checkedC"
            />
          </div>

          {state.paymentMethod === "AddCard" && (
            <div style={{ marginTop: "20px" }}>
              {cardList && cardList.length > 0 ? (
                [cardList[selectedCardIndex]].map((card, i) => {
                  return (
                    card &&
                    card.scheme && (
                      <div
                        style={{
                          padding: "10px",
                          boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.25)",
                          borderRadius: "5px",
                          color: "#a7b1c9",
                          marginBottom: "15px",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                        onClick={() => {
                          setIsModalVisible(true);
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={cardIcons[card.scheme.toLowerCase()]}
                            alt={""}
                            style={{
                              width: 44,
                              height: 34,
                              resizeMode: "contain",
                            }}
                          />
                          <span style={{ marginLeft: 20 }}>
                            Credit Card ....{card.last4}
                          </span>
                        </div>
                        <img src={ExpandDownIcon} alt="down-arrow" />
                      </div>
                    )
                  );
                })
              ) : (
                <></>
              )}
            </div>
          )}

          {cardSaved ? (
            <Collapse in={cardSaved}>
              <Alert
                severity="success"
                style={{ marginTop: 20, fontSize: 14 }}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setCardSaved(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                <AlertTitle>Success</AlertTitle>
                Card has been added successfully, please select card to continue
              </Alert>
            </Collapse>
          ) : (
            <></>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
              }}
            >
              <input
                placeholder="Enter Promo Code"
                value={promoCode}
                onChange={(text) => setPromoCode(text.target.value)}
                style={{
                  fontSize: 18,
                  borderWidth: "0px 0px 1px 0px",
                  borderColor: "#000",
                  textDecoration: "none",
                  margin: "10px 0 0 0",
                  outline: "none",
                }}
              />
              {helperText && (
                <span style={{ color: error ? "red" : "green" }}>
                  {helperText}
                </span>
              )}
            </div>
            <div
              className="book-btn2"
              style={{
                height: 40,
                width: 85,
                alignSelf: "flex-end",
                padding: 10,
              }}
              onClick={applyPromoCode}
            >
              APPLY
            </div>
          </div>
        </div>
      </div>
      {(state.paymentMethod === "Cash" || cardList.length !== 0) && (
        <StyledButton
          variant="contained"
          color="primary"
          onClick={setPaymentMode}
        >
          {props.activeStep === props.steps.length - 1 ? "Finish" : "Next"}
        </StyledButton>
      )}

      <Modal
        isOpen={isModalVisible}
        onRequestClose={() => {
          setIsModalVisible(false);
          setState({ paymentMethod: "AddCard" });
          setCardError(false);
          setCardSaved(false);
        }}
        style={{
          overlay: {
            backgroundColor: "rgba(255,255,255,0.25)",
          },
          content: {
            backgroundColor: "#F5F5FA",
            borderRadius: 10,
            borderWidth: 0,
            boxShadow: "5px 5px 10px 10px rgba(0, 0, 0, 0.25)",
          },
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <Close
            onClick={() => {
              setIsModalVisible(false);
            }}
            fontSize="large"
          />
        </div>
        <div className="modal-container">
          <strong style={{ fontSize: 30 }}>Select Card</strong>

          <div style={{ marginTop: "20px" }}>
            {cardList && cardList.length ? (
              cardList.map((card, i) => {
                return (
                  card &&
                  card.scheme && (
                    <div
                      style={{
                        padding: "10px",
                        boxShadow: "2px 0px 10px #bbb",
                        borderRadius: "5px",
                        color: "#a7b1c9",
                        marginBottom: "15px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                      onClick={onSelectCard.bind(this, i)}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          alignItems: "center",
                        }}
                      >
                        <img
                          src={cardIcons[card.scheme.toLowerCase()]}
                          alt={""}
                          style={{
                            width: 44,
                            height: 34,
                            resizeMode: "contain",
                          }}
                        />
                        <span style={{ marginLeft: 20 }}>
                          Credit Card ....{card.last4}
                        </span>
                      </div>
                      {selectedCardIndex === i ? (
                        <div id="tick-mark"></div>
                      ) : null}
                      {/* <IOSSwitch
                        checked={selectedCardIndex === i}
                        onChange={(e) => setSelectedCardIndex(i)}
                        value="checkedA"
                      /> */}
                    </div>
                  )
                );
              })
            ) : (
              <></>
            )}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              background: "#4A5BB9",
              height: 50,
              padding: 10,
              borderRadius: 5,
              margin: "2% 0 2% 0",
            }}
            onClick={() => setShowAddCard((prev) => !prev)}
          >
            <span style={{ color: "#ffffff" }}>Add Card</span>
            <img
              src={AddIcon}
              alt="add_icon"
              style={{
                height: 24,
                width: 24,
              }}
            />
          </div>
          {showAddCard && (
            <div style={{ textAlign: "center" }}>
              <div
                style={{
                  display: "flex",
                  marginTop: "20px",
                  marginBottom: "20px",
                }}
              >
                <Frames
                  config={{
                    cardholder: {
                      name: cardholder.name,
                    },
                    debug: true,
                    publicKey: "pk_cmvjhc2ytfeq3l4atspspd6c6at",
                    localization: {
                      cardNumberPlaceholder: "1234 5678 1234 5678",
                      expiryMonthPlaceholder: "MM",
                      expiryYearPlaceholder: "YY",
                      cvvPlaceholder: "CVC",
                    },
                    style: {
                      base: {
                        fontSize: "14px",
                        textAlign: "center",
                      },
                    },
                  }}
                  ready={(e) => {}}
                  frameActivated={(e) => {}}
                  frameFocus={(e) => {}}
                  frameBlur={(e) => {}}
                  frameValidationChanged={(e) => {}}
                  paymentMethodChanged={(e) => {}}
                  cardValidationChanged={(e) => {}}
                  cardSubmitted={(e) => {}}
                  cardTokenized={(e) => {}}
                  cardTokenizationFailed={(e) => {}}
                >
                  <div style={{ width: "100%" }}>
                    <div style={{ width: "100%" }}>
                      <input
                        style={{
                          backgroundColor: "#EFEFF5",
                          textAlign: "center",
                          borderRadius: 5,
                          height: 60,
                          width: "100%",
                          marginBottom: 10,
                          outline: 0,
                          boxShadow: "none",
                          borderWidth: 0,
                        }}
                        placeholder="Cardholder Name"
                        onChange={(e) => {
                          setCardholder({
                            name: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div style={{ width: "100%" }}>
                      <CardNumber style={{ margin: 0 }} />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        marginTop: 20,
                        // justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <div style={{ width: "50%" }}>
                        <ExpiryDate />
                      </div>
                      <div style={{ width: "50%" }}>
                        <Cvv />
                      </div>
                    </div>
                  </div>
                </Frames>
              </div>

              {Frames.isCardValid ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    background: "#4A5BB9",
                    height: 50,
                    padding: 10,
                    borderRadius: 5,
                  }}
                  onClick={saveCard}
                >
                  {!savingCard ? (
                    <span style={{ color: "white" }}>SAVE</span>
                  ) : (
                    <CircularProgress size={24} color="#fff" />
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          )}
          {cardError ? (
            <Collapse in={cardError}>
              <Alert
                severity="error"
                style={{ marginTop: 20, fontSize: 14 }}
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setCardError(false);
                    }}
                  >
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
              >
                <AlertTitle>Error</AlertTitle>
                Card has not been added, please check details or contact us at{" "}
                <strong>support@washon.ae</strong>
              </Alert>
            </Collapse>
          ) : (
            <></>
          )}
        </div>
      </Modal>
    </div>
  );
}
