import React from "react";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Helmet } from 'react-helmet';



export default class Faq extends React.Component {
  render() {
    return (
      <div>
                    <Helmet>
    <title>WashOn Laundry, Curtain, Carpet and Shoe Cleaning: FAQ </title>    
    <meta property="title" content="WashOn Laundry, Curtain, Carpet and Shoe Cleaning: FAQ" />
    <meta name="description" content="WashOn Laundry's FAQ: Discover solutions to common queries about our laundry, shoe cleaning, carpet cleaning and curtain cleaning services, policies, and more. Get informed today!" />
    <meta name="keywords" content="Best laundry service in dubai, Laundry, Laundry in JVC, Laundry service near me, Dry Cleaning In Dubai, Shoe cleaning in Dubai, Best laundry in dubai, Laundry Services, Carpet cleaner, laundry near me, laundry in Al furjan"/>
    <meta property="og:locale" content="en_US" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="WashOn Laundry, Curtain, Carpet and Shoe Cleaning: FAQ" />
    <meta property="og:description" content="WashOn Laundry's FAQ: Discover solutions to common queries about our laundry, shoe cleaning, carpet cleaning and curtain cleaning services, policies, and more. Get informed today!" />
    <meta name="og:keywords" content="Best laundry service in dubai, Laundry, Laundry in JVC, Laundry service near me, Dry Cleaning In Dubai, Shoe cleaning in Dubai, Best laundry in dubai, Laundry Services, Carpet cleaner, laundry near me, laundry in Al furjan"/>
    <meta property="og:url" content="https://www.washon.ae/faq" />
    <link rel="canonical" href="https://www.washon.ae/faq" />
    <meta property="og:site_name" content="WashOn Laundry Service: FAQ" />
    <meta property="og:image" content="https://washon-image.s3.ap-south-1.amazonaws.com/site-image/washon-white-bg.png" />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:title" content="WashOn Laundry, Curtain, Carpet and Shoe Cleaning: FAQ" />
    <meta name="twitter:description" content="WashOn Laundry's FAQ: Discover solutions to common queries about our laundry, shoe cleaning, carpet cleaning and curtain cleaning services, policies, and more. Get informed today!"/>
    <meta name="twitter:image" content="https://washon-image.s3.ap-south-1.amazonaws.com/site-image/washon-white-bg.png"/>
        {/* Add other head content specific to the about page */}
        </Helmet>

        {/* <div
          className="header-image"
          style={{ position: "absolute", top: "0", zIndex: "-1" }}
        >
          <img
            alt=""
            src={
              window.innerWidth > 1440
                ? largeBackgroundImg
                : window.innerWidth > 768
                ? backgroundImg
                : backgroundMobileImg
            }
          />
        </div>
        <div>
          <div className="heading">F.A.Q</div>
        </div> */}
        <div className="content-container content-container-faq">
        <h1 style={{marginTop:30, marginBottom: 0, fontSize: 35, color: "rgb(30, 30, 93)"}}>
        WashOn Laundry Service: Frequently Asked Questions
        </h1>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography style={{ fontSize: 14, color: "rgb(30, 30, 93)" }}>
                What is WashOn
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography style={{ fontSize: 16, color: "rgb(143, 152, 179)" }}>
                WashOn is laundry and dry cleaning service.We provide best
                laundry and dry cleaning service.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography style={{ fontSize: 14, color: "rgb(30, 30, 93)" }}>
                Do you have any minimum charge.
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography style={{ fontSize: 16, color: "rgb(143, 152, 179)" }}>
                Yes we have 35 AED minimum charge.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography style={{ fontSize: 14, color: "rgb(30, 30, 93)" }}>
                Do I need to be home during pickup or drop off?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography style={{ fontSize: 16, color: "rgb(143, 152, 179)" }}>
                Not required, you can leave your items outside of door. Our
                captain will pick up item from door, while delivering we will
                contact you in case you are not at home.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography style={{ fontSize: 14, color: "rgb(30, 30, 93)" }}>
                Can you remove the stain on my items?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography style={{ fontSize: 16, color: "rgb(143, 152, 179)" }}>
                We are equipped with highest quality stain removal item. We
                cannot promise all stain will be removed, but we do our best.
                Please tell us which item has stain and you would like our
                special attention.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography style={{ fontSize: 14, color: "rgb(30, 30, 93)" }}>
                What is the process to schedule a pick up?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography style={{ fontSize: 16, color: "rgb(143, 152, 179)" }}>
                Login to our website and schedule a pick up. You can choose your
                laundry pick up time during scheduling the laundry. Addition to
                this you can whatsapp your pickup time.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography style={{ fontSize: 14, color: "rgb(30, 30, 93)" }}>
                By when will I get my laundry back?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography style={{ fontSize: 16, color: "rgb(143, 152, 179)" }}>
                We deliver laundry within 48 hours.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography style={{ fontSize: 14, color: "rgb(30, 30, 93)" }}>
                Do you wash my clothes with other people clothes?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography style={{ fontSize: 16, color: "rgb(143, 152, 179)" }}>
                We understand what hygiene means to you. We don't wash your
                clothes with other people clothes.{" "}
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography style={{ fontSize: 14, color: "rgb(30, 30, 93)" }}>
                How will I pay to WashOn?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography style={{ fontSize: 16, color: "rgb(143, 152, 179)" }}>
                Washon offers online payment or Cash on delivery.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography style={{ fontSize: 14, color: "rgb(30, 30, 93)" }}>
                What will you do if my clothes are damaged when I receive them?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography style={{ fontSize: 16, color: "rgb(143, 152, 179)" }}>
                Firstly our expert laundry care team will handle your garments
                with care. They own specialized experience in garment laundry
                service. But unfortunately if you find any garment damaged, we
                will compensate to your loss 5 times of laundry price for that
                item. We understand that the liability of any garment is not
                limited, according to the industry policy of Washon we try our
                best to sort out the issue the effective way.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography style={{ fontSize: 14, color: "rgb(30, 30, 93)" }}>
                What if I leave any personnel items in my pockets by mistake?
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Typography style={{ fontSize: 16, color: "rgb(143, 152, 179)" }}>
                We shall check the laundry while pick up and return back if we
                find any personnel belongings. In case we find any at our store
                during the laundry preparing or cleaning process, we will take
                care of them and shall call you to let you know about the same.
              </Typography>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </div>
      </div>
    );
  }
}
