import React from "react";



export default function Service(props) {

  return (
    <div className="features-tab">
    <div className="feature-subtab">
      <img alt={props.title} src={props.icon} />
      <div>
        <h4>{props.title}</h4>
        <p>
          {props.subtitle}
        </p>
      </div>
    </div>
    </div>
  );
}
