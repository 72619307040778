import React from "react";
import largeBackgroundImg from "../images/page_background_sec_web_large.svg";
import backgroundImg from "../images/page_background_sec_web.svg";
import backgroundMobileImg from "../images/page_background_sec_mobile2.svg";

export default class TnC extends React.Component {
  render() {
    return (
      <div>
        <div
          className="header-image"
          style={{ position: "absolute", top: "0", zIndex: "-1" }}
        >
          <img
            alt=""
            src={
              window.innerWidth > 1440
                ? largeBackgroundImg
                : window.innerWidth > 768
                ? backgroundImg
                : backgroundMobileImg
            }
          />
        </div>
        <div>
          <div className="heading">Terms & Conditions</div>
        </div>
        <div className="content-container content-container-tc">
          Welcome to Washon Terms and Conditions Page. If you decide to continue
          to browse and use our website and services, you are completely
          agreeing to WashOn terms and conditions of use, which combining with
          our privacy policy govern WashOn’s relationship with you in relation
          to this website and its services. The terms ‘WashOn’ or ‘we’ or ‘us’
          all refer to the WashOn Dry Cleaning and Laundry Services, SPAN
          PRECAST - Building 1, Plot no. 195 Waehouse 1 DIP - 2 - Dubai. The
          term ‘you’ refers to the user of our services or the viewer of our
          website.
          <br />
          <br />
          The use of this website is subject but not limited to the below terms
          of use:
          <ol>
            <li>
              The content of the pages of this website is for the explanation
              and implementation of our services. It is subject to change
              without notice.
            </li>
            <li>
              We use cookies to monitor browsing preferences. If you allow
              cookies to be used, the relevant personal information may be
              stored by us for use in fields we determine.
            </li>
            <li>
              WashOn keeps the right to change, amend, cancel or make exceptions
              to the terms and conditions listed here. Please check this terms
              and conditions page from time to time. By using our services, you
              acknowledge that you agree with our terms and conditions.
            </li>
            <li>
              No partnership, joint venture, or agency is created as a result of
              the Terms and Conditions and you do not have any authority of any
              kind to link WashOn any respect whatsoever. WashOn will not be
              liable for any failure to perform its obligations hereunder where
              such failure results arising from any reason beyond WashOn's
              reasonable control, including, without limitation, electronic,
              mechanical or communications failure or degradation.
            </li>
            <li>
              All materials on the website are owned by or licensed to us. This
              material includes, but is not limited to layout, the design, look,
              appearance, graphics, and such. Reproduction is strictly
              prohibited other than in accordance with the copyright notice,
              which forms part of these terms and conditions.
            </li>
            <li>
              You give your consent that you will not do unauthorized use of the
              materials and information on the website.
            </li>
            <li>
              {" "}
              We may also include links to other websites. These external links
              are provided for your convenience to provide further information.
              We are not responsible for the accuracy and correctness of the
              external link content.
            </li>
            <li>
              Your use of this website and any dispute arising out of such use
              of the website is subject to the local laws of UAE Main Courts.
            </li>
            <li>
              WashOn may change rates and fees from time to time by posting the
              changes on the WashOn website 7 days in advance, but with no
              advance notice mandatory for temporary promotions or any changes
              that result in the reduction of rates and fees
            </li>
            <li>
              WashOn will charge you for your finalized bookings through the
              payment method specified on the website (e.g. a credit card or
              cash). If you happen to pay any applicable charges with your
              credit card, we may seek pre-authorisation of your credit card
              account prior to your purchase.
            </li>
            <li>
              If you choose to pay the fee by your credit card, you, as the
              cardholder, must retain a copy of transaction records and our
              policies and rules.
            </li>
            <li>
              Customers using the website who are minor/under the age of 18
              shall not register as a User of the website and shall not transact
              on or use the website.
            </li>
            <li>
              WashOn will send messages via Whatsapp, SMS, email or
              notifications as means of communication for the purposes of
              including but not limited to appointment confirmation &
              appointment updates and marketing.
            </li>
          </ol>
          <div>
            <h6>Method of Payment, Card Types accepted and Currency</h6>
            <p>
              We accept payments online using Visa, MasterCard, and Amex
              credit/debit card in AED (or any other currency agreed).
            </p>
          </div>
          <div>
            <h6>Refund/Return policy</h6>
            <p>
              Refunds will be done only through the Original Mode of Payment, or
              agreed with Customer.
            </p>
          </div>
          <div>
            <h6>Quality Assurance Program / Quality Control Policy</h6>
            <p>
              We conduct random quality inspections on a regular basis to review
              the quality of our crew members' work while they are on duty.
              <br />
              <br />
              <br />
            </p>
          </div>
        </div>
      </div>
    );
  }
}
