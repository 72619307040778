import locationIcon from "../images/location-icon.png";


export const areas = [
  {
    "id": 1,
    "link": "al-furjan",
    "title": "Al Furjan",
    "heading": "Professional Laundry Service in Al Furjan",
    "pageTitle": "WashOn Laundry Service in Dubai Al Furjan | Laundry Near me",
    "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Al Furjan with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
    "subtitle": "WashOn offers professional laundry services in Dubai Al Furjan with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
    "icon": locationIcon,
    "image": locationIcon,
  },
  {
    "id": 2,
    "link": "marina",
    "title": "Marina",
    "heading": "Professional Laundry Service in Marina",
    "pageTitle": "WashOn Laundry Service in Dubai Marina | Laundry Near me",
    "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Marina with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
    "subtitle": "WashOn offers professional laundry services in Dubai Marina with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
    "icon": locationIcon,
    "image": locationIcon,
  },
  {
    "id": 3,
    "link": "jumeirah-lake-tower",
    "title": "Jumeirah Lake Tower",
    "heading": "Professional Laundry Service in Jumeirah Lake Tower",
    "pageTitle": "WashOn Laundry Service in Dubai Jumeirah Lake Tower | Laundry Near me",
    "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Jumeirah Lake Tower with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
    "subtitle": "WashOn offers professional laundry services in Dubai Jumeirah Lake Tower with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
    "icon": locationIcon,
    "image": locationIcon,
  },
  {
    "id": 4,
    "link": "al-barsha",
    "title": "Al Barsha",
    "heading": "Professional Laundry Service in Al Barsha",
    "pageTitle": "WashOn Laundry Service in Dubai Al Barsha | Laundry Near me",
    "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Al Barsha with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
    "subtitle": "WashOn offers professional laundry services in Dubai Al Barsha with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
    "icon": locationIcon,
    "image": locationIcon,
  },
  {
    "id": 5,
    "link": "jumeirah-village-circle",
    "title": "Jumeirah Village Circle",
    "heading": "Professional Laundry Service in Jumeirah Village Circle",
    "pageTitle": "WashOn Laundry Service in Dubai Jumeirah Village Circle | Laundry Near me",
    "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Jumeirah Village Circle with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
    "subtitle": "WashOn offers professional laundry services in Dubai Jumeirah Village Circle with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
    "icon": locationIcon,
    "image": locationIcon,
  },
  {
    "id": 6,
    "link": "jumeirah-village-triangle",
    "title": "Jumeirah Village Triangle",
    "heading": "Professional Laundry Service in Jumeirah Village Triangle",
    "pageTitle": "WashOn Laundry Service in Dubai Jumeirah Village Triangle | Laundry Near me",
    "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Jumeirah Village Triangle with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
    "subtitle": "WashOn offers professional laundry services in Dubai Jumeirah Village Triangle with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
    "icon": locationIcon,
    "image": locationIcon,
  },
  {
    "id": 7,
    "link": "dubai-investment-park-1",
    "title": "Dubai Investment Park - 1",
    "heading": "Professional Laundry Service in Dubai Investment Park - 1",
    "pageTitle": "WashOn Laundry Service in Dubai Investment Park - 1 | Laundry Near me",
    "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Investment Park - 1 with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
    "subtitle": "WashOn offers professional laundry services in Dubai Investment Park - 1 with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
    "icon": locationIcon,
    "image": locationIcon,
  },
  {
    "id": 8,
    "link": "dubai-investment-park-2",
    "title": "Dubai Investment Park - 2",
    "heading": "Professional Laundry Service in Dubai Investment Park - 2",
    "pageTitle": "WashOn Laundry Service in Dubai Investment Park - 2 | Laundry Near me",
    "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Investment Park - 2 with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
    "subtitle": "WashOn offers professional laundry services in Dubai Investment Park - 2 with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
    "icon": locationIcon,
    "image": locationIcon,
  },
  {
    "id": 9,
    "link": "dubai-hills",
    "title": "Dubai Hills",
    "heading": "Professional Laundry Service in Dubai Hills",
    "pageTitle": "WashOn Laundry Service in Dubai Hills | Laundry Near me",
    "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Hills with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
    "subtitle": "WashOn offers professional laundry services in Dubai Hills with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
    "icon": locationIcon,
    "image": locationIcon,
  },
  {
    "id": 10,
    "link": "al-barsha-south",
    "title": "Al Barsha South",
    "heading": "Professional Laundry Service in Al Barsha South",
    "pageTitle": "WashOn Laundry Service in Dubai Al Barsha South | Laundry Near me",
    "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Al Barsha South with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
    "subtitle": "WashOn offers professional laundry services in Dubai Al Barsha South with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
    "icon": locationIcon,
    "image": locationIcon,
  },
  {
    "id": 11,
    "link": "arjan",
    "title": "Arjan",
    "heading": "Professional Laundry Service in Arjan",
    "pageTitle": "WashOn Laundry Service in Dubai Arjan | Laundry Near me",
    "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Arjan with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
    "subtitle": "WashOn offers professional laundry services in Dubai Arjan with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
    "icon": locationIcon,
    "image": locationIcon,
  },
  {
    "id": 12,
    "link": "production-city",
    "title": "Production City",
    "heading": "Professional Laundry Service in Production City",
    "pageTitle": "WashOn Laundry Service in Dubai Production City | Laundry Near me",
    "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Production City with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
    "subtitle": "WashOn offers professional laundry services in Dubai Production City with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
    "icon": locationIcon,
    "image": locationIcon,
  },
    {
      "id": 13,
      "link": "the-gardens",
      "title": "The Gardens",
      "heading": "Professional Laundry Service in The Gardens",
      "pageTitle": "WashOn Laundry Service in Dubai The Gardens | Laundry Near me",
      "description": "WashOn Laundry offers fast, reliable laundry services in Dubai The Gardens with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
      "subtitle": "WashOn offers professional laundry services in Dubai The Gardens with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
      "icon": locationIcon,
      "image": locationIcon,
    },
    {
      "id": 14,
      "link": "discovery-garden",
      "title": "Discovery Garden",
      "heading": "Professional Laundry Service in Discovery Garden",
      "pageTitle": "WashOn Laundry Service in Dubai Discovery Garden | Laundry Near me",
      "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Discovery Garden with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
      "subtitle": "WashOn offers professional laundry services in Dubai Discovery Garden with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
      "icon": locationIcon,
      "image": locationIcon,
    },
    {
      "id": 15,
      "link": "ibn-battuta",
      "title": "IBN Battuta",
      "heading": "Professional Laundry Service in IBN Battuta",
      "pageTitle": "WashOn Laundry Service in Dubai IBN Battuta | Laundry Near me",
      "description": "WashOn Laundry offers fast, reliable laundry services in Dubai IBN Battuta with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
      "subtitle": "WashOn offers professional laundry services in Dubai IBN Battuta with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
      "icon": locationIcon,
      "image": locationIcon,
    },
    {
      "id": 16,
      "link": "jebel-ali-village",
      "title": "Jebel Ali Village",
      "heading": "Professional Laundry Service in Jebel Ali Village",
      "pageTitle": "WashOn Laundry Service in Dubai Jebel Ali Village | Laundry Near me",
      "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Jebel Ali Village with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
      "subtitle": "WashOn offers professional laundry services in Dubai Jebel Ali Village with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
      "icon": locationIcon,
      "image": locationIcon,
    },
    {
      "id": 17,
      "link": "springs",
      "title": "Springs",
      "heading": "Professional Laundry Service in Springs",
      "pageTitle": "WashOn Laundry Service in Dubai Springs | Laundry Near me",
      "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Springs with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
      "subtitle": "WashOn offers professional laundry services in Dubai Springs with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
      "icon": locationIcon,
      "image": locationIcon,
    },
    {
      "id": 18,
      "link": "meadows",
      "title": "Meadows",
      "heading": "Professional Laundry Service in Meadows",
      "pageTitle": "WashOn Laundry Service in Dubai Meadows | Laundry Near me",
      "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Meadows with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
      "subtitle": "WashOn offers professional laundry services in Dubai Meadows with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
      "icon": locationIcon,
      "image": locationIcon,
    },
    {
      "id": 19,
      "link": "vida-hills",
      "title": "Vida Hills",
      "heading": "Professional Laundry Service in Vida Hills",
      "pageTitle": "WashOn Laundry Service in Dubai Vida Hills | Laundry Near me",
      "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Vida Hills with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
      "subtitle": "WashOn offers professional laundry services in Dubai Vida Hills with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
      "icon": locationIcon,
      "image": locationIcon,
    },
    {
      "id": 20,
      "link": "jumeirah-park",
      "title": "Jumeirah Park",
      "heading": "Professional Laundry Service in Jumeirah Park",
      "pageTitle": "WashOn Laundry Service in Dubai Jumeirah Park | Laundry Near me",
      "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Jumeirah Park with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
      "subtitle": "WashOn offers professional laundry services in Dubai Jumeirah Park with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
      "icon": locationIcon,
      "image": locationIcon,
    },
      {
        "id": 21,
        "link": "emirates-hills",
        "title": "Emirates Hills",
        "heading": "Professional Laundry Service in Emirates Hills",
        "pageTitle": "WashOn Laundry Service in Dubai Emirates Hills | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Emirates Hills with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Emirates Hills with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 22,
        "link": "the-lakes",
        "title": "The Lakes",
        "heading": "Professional Laundry Service in The Lakes",
        "pageTitle": "WashOn Laundry Service in Dubai The Lakes | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai The Lakes with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai The Lakes with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 23,
        "link": "the-views",
        "title": "The Views",
        "heading": "Professional Laundry Service in The Views",
        "pageTitle": "WashOn Laundry Service in Dubai The Views | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai The Views with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai The Views with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 24,
        "link": "murooj-al-furjan",
        "title": "Murooj Al Furjan",
        "heading": "Professional Laundry Service in Murooj Al Furjan",
        "pageTitle": "WashOn Laundry Service in Dubai Murooj Al Furjan | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Murooj Al Furjan with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Murooj Al Furjan with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 25,
        "link": "blue-water-island",
        "title": "Blue Water Island",
        "heading": "Professional Laundry Service in Blue Water Island",
        "pageTitle": "WashOn Laundry Service in Dubai Blue Water Island | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Blue Water Island with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Blue Water Island with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 26,
        "link": "jbr",
        "title": "JBR",
        "heading": "Professional Laundry Service in JBR",
        "pageTitle": "WashOn Laundry Service in Dubai JBR | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai JBR with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai JBR with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 27,
        "link": "media-city",
        "title": "Media City",
        "heading": "Professional Laundry Service in Media City",
        "pageTitle": "WashOn Laundry Service in Dubai Media City | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Media City with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Media City with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 28,
        "link": "palm-jumeirah",
        "title": "Palm Jumeirah",
        "heading": "Professional Laundry Service in Palm Jumeirah",
        "pageTitle": "WashOn Laundry Service in Dubai Palm Jumeirah | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Palm Jumeirah with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Palm Jumeirah with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 29,
        "link": "al-quoz",
        "title": "Al Quoz",
        "heading": "Professional Laundry Service in Al Quoz",
        "pageTitle": "WashOn Laundry Service in Dubai Al Quoz | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Al Quoz with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Al Quoz with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 30,
        "link": "al-sofouh",
        "title": "Al Sofouh",
        "heading": "Professional Laundry Service in Al Sofouh",
        "pageTitle": "WashOn Laundry Service in Dubai Al Sofouh | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Al Sofouh with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Al Sofouh with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 31,
        "link": "internet-city",
        "title": "Internet City",
        "heading": "Professional Laundry Service in Internet City",
        "pageTitle": "WashOn Laundry Service in Dubai Internet City | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Internet City with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Internet City with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 32,
        "link": "greens",
        "title": "Greens",
        "heading": "Professional Laundry Service in Greens",
        "pageTitle": "WashOn Laundry Service in Dubai Greens | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Greens with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Greens with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 33,
        "link": "al-barsha-heights",
        "title": "Al Barsha Heights",
        "heading": "Professional Laundry Service in Al Barsha Heights",
        "pageTitle": "WashOn Laundry Service in Dubai Al Barsha Heights | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Al Barsha Heights with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Al Barsha Heights with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 34,
        "link": "knowledge-village",
        "title": "Knowledge Village",
        "heading": "Professional Laundry Service in Knowledge Village",
        "pageTitle": "WashOn Laundry Service in Dubai Knowledge Village | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Knowledge Village with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Knowledge Village with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 35,
        "link": "tecom",
        "title": "Tecom",
        "heading": "Professional Laundry Service in Tecom",
        "pageTitle": "WashOn Laundry Service in Dubai Tecom | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Tecom with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Tecom with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 36,
        "link": "mira-oasis",
        "title": "Mira Oasis",
        "heading": "Professional Laundry Service in Mira Oasis",
        "pageTitle": "WashOn Laundry Service in Dubai Mira Oasis | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Mira Oasis with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Mira Oasis with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 37,
        "link": "motor-city",
        "title": "Motor City",
        "heading": "Professional Laundry Service in Motor City",
        "pageTitle": "WashOn Laundry Service in Dubai Motor City | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Motor City with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Motor City with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 38,
        "link": "qudra",
        "title": "Qudra",
        "heading": "Professional Laundry Service in Qudra",
        "pageTitle": "WashOn Laundry Service in Dubai Qudra | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Qudra with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Qudra with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 39,
        "link": "sports-city",
        "title": "Sports City",
        "heading": "Professional Laundry Service in Sports City",
        "pageTitle": "WashOn Laundry Service in Dubai Sports City | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Sports City with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Sports City with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 40,
        "link": "akoya",
        "title": "Akoya",
        "heading": "Professional Laundry Service in Akoya",
        "pageTitle": "WashOn Laundry Service in Dubai Akoya | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Akoya with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Akoya with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 41,
        "link": "impz",
        "title": "IMPZ",
        "heading": "Professional Laundry Service in IMPZ",
        "pageTitle": "WashOn Laundry Service in Dubai IMPZ | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai IMPZ with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai IMPZ with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 42,
        "link": "al-qudra-roads",
        "title": "Al Qudra Roads",
        "heading": "Professional Laundry Service in Al Qudra Roads",
        "pageTitle": "WashOn Laundry Service in Dubai Al Qudra Roads | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Al Qudra Roads with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Al Qudra Roads with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 43,
        "link": "jumeirah-golf-estate",
        "title": "Jumeirah Golf Estate",
        "heading": "Professional Laundry Service in Jumeirah Golf Estate",
        "pageTitle": "WashOn Laundry Service in Dubai Jumeirah Golf Estate | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Jumeirah Golf Estate with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Jumeirah Golf Estate with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 44,
        "link": "arabian-ranches-1",
        "title": "Arabian Ranches 1",
        "heading": "Professional Laundry Service in Arabian Ranches 1",
        "pageTitle": "WashOn Laundry Service in Dubai Arabian Ranches 1 | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Arabian Ranches 1 with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Arabian Ranches 1 with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 45,
        "link": "arabian-ranches-2",
        "title": "Arabian Ranches 2",
        "heading": "Professional Laundry Service in Arabian Ranches 2",
        "pageTitle": "WashOn Laundry Service in Dubai Arabian Ranches 2 | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Arabian Ranches 2 with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Arabian Ranches 2 with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 46,
        "link": "damac-hills",
        "title": "Damac Hills",
        "heading": "Professional Laundry Service in Damac Hills",
        "pageTitle": "WashOn Laundry Service in Dubai Damac Hills | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Damac Hills with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Damac Hills with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 47,
        "link": "mudon",
        "title": "Mudon",
        "heading": "Professional Laundry Service in Mudon",
        "pageTitle": "WashOn Laundry Service in Dubai Mudon | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Mudon with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Mudon with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 48,
        "link": "sustainable-city",
        "title": "Sustainable City",
        "heading": "Professional Laundry Service in Sustainable City",
        "pageTitle": "WashOn Laundry Service in Dubai Sustainable City | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Sustainable City with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Sustainable City with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 49,
        "link": "remraam",
        "title": "Remraam",
        "heading": "Professional Laundry Service in Remraam",
        "pageTitle": "WashOn Laundry Service in Dubai Remraam | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Remraam with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Remraam with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 50,
        "link": "leyan-community",
        "title": "Leyan Community",
        "heading": "Professional Laundry Service in Leyan Community",
        "pageTitle": "WashOn Laundry Service in Dubai Leyan Community | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Leyan Community with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Leyan Community with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 51,
        "link": "business-bay",
        "title": "Business Bay",
        "heading": "Professional Laundry Service in Business Bay",
        "pageTitle": "WashOn Laundry Service in Dubai Business Bay | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Business Bay with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Business Bay with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 52,
        "link": "dubai-design-district",
        "title": "Dubai Design District",
        "heading": "Professional Laundry Service in Dubai Design District",
        "pageTitle": "WashOn Laundry Service in Dubai Design District | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Design District with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Design District with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 53,
        "link": "trade-center",
        "title": "Trade Center",
        "heading": "Professional Laundry Service in Trade Center",
        "pageTitle": "WashOn Laundry Service in Dubai Trade Center | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Trade Center with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Trade Center with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 54,
        "link": "zabeel",
        "title": "Zabeel",
        "heading": "Professional Laundry Service in Zabeel",
        "pageTitle": "WashOn Laundry Service in Dubai Zabeel | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Zabeel with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Zabeel with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 55,
        "link": "downtown",
        "title": "Downtown",
        "heading": "Professional Laundry Service in Downtown",
        "pageTitle": "WashOn Laundry Service in Dubai Downtown | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Downtown with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Downtown with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 56,
        "link": "financial-center",
        "title": "Financial Center",
        "heading": "Professional Laundry Service in Financial Center",
        "pageTitle": "WashOn Laundry Service in Dubai Financial Center | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Financial Center with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Financial Center with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 57,
        "link": "burj-residence",
        "title": "Burj Residence",
        "heading": "Professional Laundry Service in Burj Residence",
        "pageTitle": "WashOn Laundry Service in Dubai Burj Residence | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Burj Residence with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Burj Residence with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 58,
        "link": "marasi-drive",
        "title": "Marasi Drive",
        "heading": "Professional Laundry Service in Marasi Drive",
        "pageTitle": "WashOn Laundry Service in Dubai Marasi Drive | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Marasi Drive with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Marasi Drive with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 59,
        "link": "jumeirah-1",
        "title": "Jumeirah 1",
        "heading": "Professional Laundry Service in Jumeirah 1",
        "pageTitle": "WashOn Laundry Service in Dubai Jumeirah 1 | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Jumeirah 1 with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Jumeirah 1 with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 60,
        "link": "jumeirah-2",
        "title": "Jumeirah 2",
        "heading": "Professional Laundry Service in Jumeirah 2",
        "pageTitle": "WashOn Laundry Service in Dubai Jumeirah 2 | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Jumeirah 2 with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Jumeirah 2 with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 61,
        "link": "jumeirah-3",
        "title": "Jumeirah 3",
        "heading": "Professional Laundry Service in Jumeirah 3",
        "pageTitle": "WashOn Laundry Service in Dubai Jumeirah 3 | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Jumeirah 3 with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Jumeirah 3 with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 62,
        "link": "al-jaddaf",
        "title": "Al Jaddaf",
        "heading": "Professional Laundry Service in Al Jaddaf",
        "pageTitle": "WashOn Laundry Service in Dubai Al Jaddaf | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Al Jaddaf with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Al Jaddaf with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 63,
        "link": "al-safa",
        "title": "Al Safa",
        "heading": "Professional Laundry Service in Al Safa",
        "pageTitle": "WashOn Laundry Service in Dubai Al Safa | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Al Safa with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Al Safa with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 64,
        "link": "la-mer-beach",
        "title": "La Mer Beach",
        "heading": "Professional Laundry Service in La Mer Beach",
        "pageTitle": "WashOn Laundry Service in Dubai La Mer Beach | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai La Mer Beach with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai La Mer Beach with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 65,
        "link": "al-bada",
        "title": "Al Bada",
        "heading": "Professional Laundry Service in Al Bada",
        "pageTitle": "WashOn Laundry Service in Dubai Al Bada | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Al Bada with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Al Bada with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 66,
        "link": "city-walk",
        "title": "City Walk",
        "heading": "Professional Laundry Service in City Walk",
        "pageTitle": "WashOn Laundry Service in Dubai City Walk | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai City Walk with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai City Walk with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 67,
        "link": "al-satwa",
        "title": "Al Satwa",
        "heading": "Professional Laundry Service in Al Satwa",
        "pageTitle": "WashOn Laundry Service in Dubai Al Satwa | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Al Satwa with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Al Satwa with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 68,
        "link": "al-wasi",
        "title": "Al Wasi",
        "heading": "Professional Laundry Service in Al Wasi",
        "pageTitle": "WashOn Laundry Service in Dubai Al Wasi | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Al Wasi with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Al Wasi with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 69,
        "link": "umm-suqeim",
        "title": "Umm Suqeim",
        "heading": "Professional Laundry Service in Umm Suqeim",
        "pageTitle": "WashOn Laundry Service in Dubai Umm Suqeim | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Umm Suqeim with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Umm Suqeim with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 70,
        "link": "the-villa",
        "title": "The Villa",
        "heading": "Professional Laundry Service in The Villa",
        "pageTitle": "WashOn Laundry Service in Dubai The Villa | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai The Villa with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai The Villa with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 71,
        "link": "dubai-land",
        "title": "Dubai Land",
        "heading": "Professional Laundry Service in Dubai Land",
        "pageTitle": "WashOn Laundry Service in Dubai Land | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Land with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Land with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 72,
        "link": "falcon-city",
        "title": "Falcon City",
        "heading": "Professional Laundry Service in Falcon City",
        "pageTitle": "WashOn Laundry Service in Dubai Falcon City | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Falcon City with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Falcon City with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 73,
        "link": "silicon-oasis",
        "title": "Silicon Oasis",
        "heading": "Professional Laundry Service in Silicon Oasis",
        "pageTitle": "WashOn Laundry Service in Dubai Silicon Oasis | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Silicon Oasis with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Silicon Oasis with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 74,
        "link": "academic-city",
        "title": "Academic City",
        "heading": "Professional Laundry Service in Academic City",
        "pageTitle": "WashOn Laundry Service in Dubai Academic City | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Academic City with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Academic City with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 75,
        "link": "maydan-bridge",
        "title": "Maydan Bridge",
        "heading": "Professional Laundry Service in Maydan Bridge",
        "pageTitle": "WashOn Laundry Service in Dubai Maydan Bridge | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Maydan Bridge with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Maydan Bridge with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 76,
        "link": "nad-al-sheba",
        "title": "Nad Al Sheba",
        "heading": "Professional Laundry Service in Nad Al Sheba",
        "pageTitle": "WashOn Laundry Service in Dubai Nad Al Sheba | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Nad Al Sheba with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Nad Al Sheba with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 77,
        "link": "ras-al-khor",
        "title": "Ras Al Khor",
        "heading": "Professional Laundry Service in Ras Al Khor",
        "pageTitle": "WashOn Laundry Service in Dubai Ras Al Khor | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Ras Al Khor with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Ras Al Khor with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 78,
        "link": "dubai-creek",
        "title": "Dubai Creek",
        "heading": "Professional Laundry Service in Dubai Creek",
        "pageTitle": "WashOn Laundry Service in Dubai Creek | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Creek with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Creek with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 79,
        "link": "majan",
        "title": "Majan",
        "heading": "Professional Laundry Service in Majan",
        "pageTitle": "WashOn Laundry Service in Dubai Majan | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Majan with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Majan with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 80,
        "link": "al-barari",
        "title": "Al Barari",
        "heading": "Professional Laundry Service in Al Barari",
        "pageTitle": "WashOn Laundry Service in Dubai Al Barari | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Al Barari with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Al Barari with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 81,
        "link": "maydan-villas",
        "title": "Maydan Villas",
        "heading": "Professional Laundry Service in Maydan Villas",
        "pageTitle": "WashOn Laundry Service in Dubai Maydan Villas | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Maydan Villas with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Maydan Villas with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 82,
        "link": "mirdiff",
        "title": "Mirdiff",
        "heading": "Professional Laundry Service in Mirdiff",
        "pageTitle": "WashOn Laundry Service in Dubai Mirdiff | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Mirdiff with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Mirdiff with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 83,
        "link": "expo-2020",
        "title": "Expo 2020",
        "heading": "Professional Laundry Service in Expo 2020",
        "pageTitle": "WashOn Laundry Service in Dubai Expo 2020 | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Expo 2020 with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Expo 2020 with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 84,
        "link": "emaar-south",
        "title": "Emaar South",
        "heading": "Professional Laundry Service in Emaar South",
        "pageTitle": "WashOn Laundry Service in Dubai Emaar South | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Emaar South with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Emaar South with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 85,
        "link": "al-khail-gate",
        "title": "Al Khail Gate",
        "heading": "Professional Laundry Service in Al Khail Gate",
        "pageTitle": "WashOn Laundry Service in Dubai Al Khail Gate | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Al Khail Gate with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Al Khail Gate with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 86,
        "link": "dubai-south",
        "title": "Dubai South",
        "heading": "Professional Laundry Service in Dubai South",
        "pageTitle": "WashOn Laundry Service in Dubai South | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai South with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai South with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 87,
        "link": "tilal-al-ghaf",
        "title": "Tilal Al Ghaf",
        "heading": "Professional Laundry Service in Tilal Al Ghaf",
        "pageTitle": "WashOn Laundry Service in Dubai Tilal Al Ghaf | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Tilal Al Ghaf with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Tilal Al Ghaf with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 88,
        "link": "masakin-al-furjan",
        "title": "Masakin Al Furjan",
        "heading": "Professional Laundry Service in Masakin Al Furjan",
        "pageTitle": "WashOn Laundry Service in Dubai Masakin Al Furjan | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Masakin Al Furjan with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Masakin Al Furjan with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      {
        "id": 89,
        "link": "green-community",
        "title": "Green Community",
        "heading": "Professional Laundry Service in Green Community",
        "pageTitle": "WashOn Laundry Service in Dubai Green Community | Laundry Near me",
        "description": "WashOn Laundry offers fast, reliable laundry services in Dubai Green Community with convenient pickup and delivery. Your trusted solution for: Laundry Near Me.",
        "subtitle": "WashOn offers professional laundry services in Dubai Green Community with free pickup and delivery. Book now for clean, fresh clothes without the hassle!",
        "icon": locationIcon,
        "image": locationIcon,
      },
      
      
            
       
    
    ]
        

;
