import React, { Component } from "react";

class ResendButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isButtonDisabled: false,
    };
  }

  render() {
    return (
      <div
        className="resend-btn"
        id="resend"
        onClick={this.props.resendOTPCall}
        disabled={this.isButtonDisabled}
      >
        Resend Otp
      </div>
    );
  }
}

export default ResendButton;
