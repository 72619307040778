import React from "react";
import { useRouteMatch } from "react-router-dom";
import  { Redirect } from 'react-router-dom'
import LocationRoute from "./LocationRoute";




const LocationManager = (props) => {
  const match = useRouteMatch();
  const slug = match.params.slug;
  localStorage.setItem("slug",slug)
  
  
  if(slug===undefined){
    return <Redirect to='/error'  />
  }

  return <LocationRoute setBookOrder={props.setBookOrder} />
};

export default LocationManager;
