import React from "react";
import backgroundImg from "../images/background.svg";
import largeBackgroundImg from "../images/background-1920.svg";
import backgroundMobileImg from "../images/mobile-bg.svg";
import washPress from "../images/WashPress.png";
import washFold from "../images/WashFold.png";
import pressOnly from "../images/PressOnly.png";
import cleanPress from "../images/CleanPress.png";
import step1 from "../images/step1.png";
import step2 from "../images/step2.png";
import step3 from "../images/step3.png";
import feature1 from "../images/feature1.svg";
import feature2 from "../images/feature2.svg";
import feature3 from "../images/feature3.svg";
import feature4 from "../images/feature4.svg";
import phone1 from "../images/phone1.png";
import phone2 from "../images/phone2.png";
import Slider from "react-slick";
import profilePic from "../images/customer-1.png";
import downloadGoogleImg from "../images/download-google-play.png";
import downloadAppleImg from "../images/download-app-store.png";
import cleanPressSelected from "../images/CleanPressSelected.png";
import washPressSelected from "../images/WashPressSelected.png";
import pressOnlySelected from "../images/PressOnlySelected.png";
import washFoldSelected from "../images/WashFoldSelected.png";
import washFold1 from "../images/washFold1.png";
import washFold2 from "../images/washFold2.png";
import downloadApp from "../images/download-app.png";
import cleanPress1 from "../images/cleanPress1.png";
import cleanPress2 from "../images/cleanPress2.png";
import pressOnly1 from "../images/pressOnly1.png";
import pressOnly2 from "../images/pressOnly2.png";
import washPress1 from "../images/washPress1.png";
import washPress2 from "../images/washPress2.png";
import { Helmet } from 'react-helmet';
import "../css/home.css";
import { Link } from "react-router-dom";
import { schemafiles } from "../data/schemafiles";
import { areas } from "../data/areas";





export default class LocationRoute extends React.Component {
  state = {
    selectedService: null,
    openDialog: false,
    showDropdown: false,
    bookOrder: false,
  };
  componentDidMount() {
  }
  handleServiceSelection = (type) => {
    this.setState({
      selectedService: type,
    });
  };
  handleCloseDialog = () => {
    this.setState({
      openDialog: false,
    });
  };

  loggedIn = () => {
    this.setState({
      isLoggedIn: true,
    });
  };

  logout = () => {
    this.handleClose();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("userToken")}`
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch("https://api.washon.ae/api/user/logout", requestOptions)
      .then((response) => response.json())
      .then(() => {
        console.log("logged out successfully");
        localStorage.removeItem("userId");
        localStorage.removeItem("userToken");
        this.setState({ isLoggedIn: false, bookOrder: false });
      })
      .catch((error) => console.error("error", error));
  };

  isLoggedIn = () => {
  
    if (localStorage.getItem("userToken")) {
      return true;
    }
    return false;
  };

  setBookOrder = () => {
    if (!this.state.bookOrder && !localStorage.getItem("userToken")) {
      this.setState({
        openDialog: true,
      });
    } else
      this.setState((prevState) => {
        return {
          bookOrder: !prevState.bookOrder,
        };
      });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  render() {
    const slug = localStorage.getItem("slug");
    const location = areas.filter((a) => a.link === slug)[0];
    const link = `https://www.washon.ae/locations/${location.link}`;
  
    var settings = {
      dots: true,
      infinite: true,
      speed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 6000,
      dotsClass: "slick-dots slick-dots-new",
    };    
    const { selectedService } = this.state;
    return (
    <div>
    {/* ${JSON.stringify(schemafiles).replace(/(\r\n|\n|\r)/gm, "") */}
    <Helmet encodeSpecialCharacters={false}>
    <link rel="preload" fetchpriority="high" href={
                  window.innerWidth > 1440
                    ? largeBackgroundImg
                    : window.innerWidth > 768
                    ? backgroundImg
                    : backgroundMobileImg
                } as="image"></link>
    (<script type="application/ld+json">{`${JSON.stringify(schemafiles)}`}</script>)
    <title> {location.pageTitle} </title>
    <link rel="canonical" href={link} />
    <meta property="title" content={location.pageTitle} />
    <meta name="description" content={location.description} />
    <meta name="keywords" content="Best laundry service in dubai, Laundry, Laundry in marina, Laundry service near me, Dry Cleaning In Dubai, Shoe cleaning in Dubai, Best laundry in dubai, Laundry Services, Dry Cleaners Near Me, laundry near me, laundry in Al furjan"/>
    <meta property="og:locale" content="en_US" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content={location.pageTitle} />
    <meta property="og:description" content={location.description} />
    <meta property="og:url" content={link} />
    <meta property="og:site_name" content="WashOn Laundry Service" />
    <meta property="og:image" content="https://washon-image.s3.ap-south-1.amazonaws.com/site-image/washon-white-bg.png" />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:title" content= {location.pageTitle}/>
    <meta name="twitter:description" content={location.description}/>
    <meta name="twitter:image" content="https://washon-image.s3.ap-south-1.amazonaws.com/site-image/washon-white-bg.png"/>
        {/* Add other head content specific to the about page */}
      </Helmet>
        <div>
          {!this.state.bookOrder && (
            <div
              className="header-image"
              style={{ position: "absolute", top: "0", zIndex: "-1" }}
            >
              <img
                alt="Background"
                src={
                  window.innerWidth > 1440
                    ? largeBackgroundImg
                    : window.innerWidth > 768
                    ? backgroundImg
                    : backgroundMobileImg
                }
              />
            </div>
          )}
          <div className="heading-container">
            <h1>{location.heading} </h1>
            <h4>
            {location.subtitle}
            </h4>
            {!this.isLoggedIn() ? (
              <div className="book-btn" onClick={this.props.setBookOrder}>
                BOOK NOW
              </div>
            ) : (
              <Link to="/booking">
                <div className="book-btn" onClick={this.props.setBookOrder}>
                  BOOK NOW
                </div>
              </Link>
            )}
          </div>
        </div>
        <div>
          <div className="services-container">
            <h3>Laundry Services</h3>
            <div className="services-images">
              <div onClick={() => this.handleServiceSelection("1")}>
                <img
                  alt="Wash and Fold"
                  src={washFold}
                  style={
                    selectedService === "1"
                      ? { boxShadow: "0 68px 46px -46px #aabef9" }
                      : {}
                  }
                />
                <span>from AED 3</span>
              </div>
              {selectedService === "1" && window.innerWidth < 768 && (
                <div className="service-selected">
                  <div>
                    <div className="service-para">
                      <p>
                        Schedule your washing or pressing services as per your
                        convenience. We will take it for there, Hassel free and
                        Simple!
                        <br />
                        <br />
                        Just be ready with your Laundry or Keep it outside your
                        door (as per your convenience), we will be right there
                        with our eco-friendly laundry beg. We will grab away all
                        the dirty clothes from your home.
                        <br />
                        <br />
                        Our experts, with the help of our state of art machinery
                        ensure that your clothes come back looking clean, tidier
                        and fresh every time. Your clothes will be delivered at
                        your door step on time and as fresh as daisy.
                      </p>
                      <div>
                        <h5>
                          This bag size is 72*50 cm, could hold upto laundry
                          equal to 2 full laundry loads at home.
                        </h5>
                        <div className="service-subdiv">
                          <span style={{ color: "rgb(30, 30, 93)" }}>
                            Information
                          </span>
                          <span
                            className="price-tag"
                            style={{ fontWeight: "bolder" }}
                          >
                            from AED 3
                          </span>
                        </div>
                        <hr />
                        <div className="service-subdiv">
                          <div>
                            <span className="heading">
                              Color split accordingly
                            </span>
                            <span className="sub-heading">No color bleeds</span>
                          </div>
                          <img alt="" src={washFold1} />
                        </div>
                        <hr />
                        <div className="service-subdiv">
                          <div>
                            <span className="heading">
                              Clothes washed separately
                            </span>
                            <span className="sub-heading">
                              No mixed with other customers
                            </span>
                          </div>
                          <img alt="" src={washFold2} />
                        </div>
                        <hr />
                        <div className="service-subdiv">
                          <div className="only-heading">
                            <span className="heading">
                              Eco-friendly detergents
                            </span>
                          </div>
                        </div>
                        <hr />
                        <div className="service-subdiv">
                          <div className="only-heading">
                            <span className="heading">
                              Gently onyour skin & clothes
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {!this.isLoggedIn() ? (
                    <div className="book-btn" onClick={this.props.setBookOrder}>
                      BOOK NOW
                    </div>
                  ) : (
                    <Link to="/booking">
                      <div
                        className="book-btn"
                        onClick={this.props.setBookOrder}
                      >
                        BOOK NOW
                      </div>
                    </Link>
                  )}
                </div>
              )}
              <div onClick={() => this.handleServiceSelection("2")}>
                <img
                  alt=""
                  src={washPress}
                  style={
                    selectedService === "2"
                      ? { boxShadow: "0 68px 46px -46px #6e6fb5" }
                      : {}
                  }
                />
                <span style={{ color: "rgb(165, 164, 201)" }}>from AED 5</span>
              </div>
              {selectedService === "2" && window.innerWidth < 768 && (
                <div className="service-selected">
                  <div>
                    <div className="service-para">
                      <p>
                        Schedule your washing or pressing services as per your
                        convenience. We will take it for there, Hassel free and
                        Simple!
                        <br />
                        <br />
                        Just be ready with your Laundry or Keep it outside your
                        door (as per your convenience), we will be right there
                        with our eco-friendly laundry beg. We will grab away all
                        the dirty clothes from your home.
                        <br />
                        <br />
                        Our experts, with the help of our state of art machinery
                        ensure that your clothes come back looking clean, tidier
                        and fresh every time. Your clothes will be delivered at
                        your door step on time and as fresh as daisy.
                      </p>
                      <div>
                        <h5>
                          This bag size is 72*50 cm, could hold upto laundry
                          equal to 2 full laundry loads at home.
                        </h5>
                        <div className="service-subdiv">
                          <span style={{ color: "rgb(30, 30, 93)" }}>
                            Information
                          </span>
                          <span
                            className="price-tag"
                            style={{ fontWeight: "bolder", color: "#424194" }}
                          >
                            from AED 5
                          </span>
                        </div>
                        <hr />
                        <div className="service-subdiv">
                          <div>
                            <span className="heading">
                              Color split accordingly
                            </span>
                            <span className="sub-heading">No color bleeds</span>
                          </div>
                          <img alt="" src={washPress1} />
                        </div>
                        <hr />
                        <div className="service-subdiv">
                          <div>
                            <span className="heading">
                              Clothes washed separately
                            </span>
                            <span className="sub-heading">
                              No mixed with other customers
                            </span>
                          </div>
                          <img alt="" src={washPress2} />
                        </div>
                        <hr />
                        <div className="service-subdiv">
                          <div className="only-heading">
                            <span className="heading">
                              Eco-friendly detergents
                            </span>
                          </div>
                        </div>
                        <hr />
                        <div className="service-subdiv">
                          <div className="only-heading">
                            <span className="heading">
                              Gently onyour skin & clothes
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {!this.isLoggedIn() ? (
                    <div className="book-btn" onClick={this.props.setBookOrder}>
                      BOOK NOW
                    </div>
                  ) : (
                    <Link to="/booking">
                      <div
                        className="book-btn"
                        onClick={this.props.setBookOrder}
                      >
                        BOOK NOW
                      </div>
                    </Link>
                  )}
                </div>
              )}
              <div onClick={() => this.handleServiceSelection("3")}>
                <img
                  alt=""
                  src={pressOnly}
                  style={
                    selectedService === "3"
                      ? { boxShadow: "0 68px 46px -46px #8c8fb7" }
                      : {}
                  }
                />
                <span style={{ color: "rgb(200, 201, 241)" }}>from AED 4</span>
              </div>
              {selectedService === "3" && window.innerWidth < 768 && (
                <div className="service-selected">
                  <div>
                    <div className="service-para">
                      <p>
                        Schedule your washing or pressing services as per your
                        convenience. We will take it for there, Hassel free and
                        Simple!
                        <br />
                        <br />
                        Just be ready with your Laundry or Keep it outside your
                        door (as per your convenience), we will be right there
                        with our eco-friendly laundry beg. We will grab away all
                        the dirty clothes from your home.
                        <br />
                        <br />
                        Our experts, with the help of our state of art machinery
                        ensure that your clothes come back looking clean, tidier
                        and fresh every time. Your clothes will be delivered at
                        your door step on time and as fresh as daisy.
                      </p>
                      <div>
                        <div className="service-subdiv">
                          <span style={{ color: "rgb(30, 30, 93)" }}>
                            Information
                          </span>
                          <span
                            className="price-tag"
                            style={{ fontWeight: "bolder", color: "#7d7fac" }}
                          >
                            from AED 4
                          </span>
                        </div>
                        <hr />
                        <div className="service-subdiv">
                          <div>
                            <span className="heading">
                              Color split accordingly
                            </span>
                            <span className="sub-heading">No color bleeds</span>
                          </div>
                          <img alt="" src={pressOnly1} />
                        </div>
                        <hr />
                        <div className="service-subdiv">
                          <div>
                            <span className="heading">
                              Clothes washed separately
                            </span>
                            <span className="sub-heading">
                              No mixed with other customers
                            </span>
                          </div>
                          <img alt="" src={pressOnly2} />
                        </div>
                        <hr />
                        <div className="service-subdiv">
                          <div className="only-heading">
                            <span className="heading">
                              Eco-friendly detergents
                            </span>
                          </div>
                        </div>
                        <hr />
                        <div className="service-subdiv">
                          <div className="only-heading">
                            <span className="heading">
                              Gently onyour skin & clothes
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {!this.isLoggedIn() ? (
                    <div className="book-btn" onClick={this.props.setBookOrder}>
                      BOOK NOW
                    </div>
                  ) : (
                    <Link to="/booking">
                      <div
                        className="book-btn"
                        onClick={this.props.setBookOrder}
                      >
                        BOOK NOW
                      </div>
                    </Link>
                  )}
                </div>
              )}
              <div onClick={() => this.handleServiceSelection("4")}>
                <img
                  alt=""
                  src={cleanPress}
                  style={
                    selectedService === "4"
                      ? { boxShadow: "0 68px 46px -46px #e98496" }
                      : {}
                  }
                />
                <span style={{ color: "rgb(255, 224, 228)" }}>from AED 11</span>
              </div>
              {selectedService === "4" && window.innerWidth < 768 && (
                <div className="service-selected">
                  <div>
                    <div className="service-para">
                      <p>
                        Schedule your washing or pressing services as per your
                        convenience. We will take it for there, Hassel free and
                        Simple!
                        <br />
                        <br />
                        Just be ready with your Laundry or Keep it outside your
                        door (as per your convenience), we will be right there
                        with our eco-friendly laundry beg. We will grab away all
                        the dirty clothes from your home.
                        <br />
                        <br />
                        Our experts, with the help of our state of art machinery
                        ensure that your clothes come back looking clean, tidier
                        and fresh every time. Your clothes will be delivered at
                        your door step on time and as fresh as daisy.
                      </p>
                      <div>
                        <div className="service-subdiv">
                          <span style={{ color: "rgb(30, 30, 93)" }}>
                            Information
                          </span>
                          <span
                            className="price-tag"
                            style={{ fontWeight: "bolder", color: "#f66a78" }}
                          >
                            from AED 11
                          </span>
                        </div>
                        <hr />
                        <div className="service-subdiv">
                          <div>
                            <span className="heading">
                              Color split accordingly
                            </span>
                            <span className="sub-heading">No color bleeds</span>
                          </div>
                          <img alt="WashOn Laundry: No color bleeds" src={cleanPress1} />
                        </div>
                        <hr />
                        <div className="service-subdiv">
                          <div>
                            <span className="heading">
                              Clothes washed separately
                            </span>
                            <span className="sub-heading">
                              No mixed with other customers
                            </span>
                          </div>
                          <img alt="WashOn Laundry: Clothes washed seperately" src={cleanPress2} />
                        </div>
                        <hr />
                        <div className="service-subdiv">
                          <div className="only-heading">
                            <span className="heading">
                              Eco-friendly detergents
                            </span>
                          </div>
                        </div>
                        <hr />
                        <div className="service-subdiv">
                          <div className="only-heading">
                            <span className="heading">
                              Gently onyour skin & clothes
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {!this.isLoggedIn() ? (
                    <div className="book-btn" onClick={this.props.setBookOrder}>
                      BOOK NOW
                    </div>
                  ) : (
                    <Link to="/booking">
                      <div
                        className="book-btn"
                        onClick={this.props.setBookOrder}
                      >
                        BOOK NOW
                      </div>
                    </Link>
                  )}
                </div>
              )}
            </div>
            <div className="service-selected">
              {selectedService === "1" && window.innerWidth > 768 && (
                <div>
                  <img alt="Wash and Fold Service" src={washFoldSelected} />
                  <div className="service-para">
                    <p>
                      Schedule your washing or pressing services as per your
                      convenience. We will take it for there, Hassel free and
                      Simple!
                      <br />
                      <br />
                      Just be ready with your Laundry or Keep it outside your
                      door (as per your convenience), we will be right there
                      with our eco-friendly laundry beg. We will grab away all
                      the dirty clothes from your home.
                      <br />
                      <br />
                      Our experts, with the help of our state of art machinery
                      ensure that your clothes come back looking clean, tidier
                      and fresh every time. Your clothes will be delivered at
                      your door step on time and as fresh as daisy.
                    </p>
                    <div>
                      <div className="service-subdiv">
                        <span style={{ color: "rgb(30, 30, 93)" }}>
                          Information
                        </span>
                        <span
                          className="price-tag"
                          style={{ fontWeight: "bolder" }}
                        >
                          from AED 3
                        </span>
                      </div>
                      <hr />
                      <div className="service-subdiv">
                        <div>
                          <span className="heading">
                            Color split accordingly
                          </span>
                          <span className="sub-heading">No color bleeds</span>
                        </div>
                        <img alt="color split icon" src={washFold1} />
                      </div>
                      <hr />
                      <div className="service-subdiv">
                        <div>
                          <span className="heading">
                            Clothes washed separately
                          </span>
                          <span className="sub-heading">
                            No mixed with other customers
                          </span>
                        </div>
                        <img alt="clothes washed separately" src={washFold2} />
                      </div>
                      <hr />
                      <div className="service-subdiv">
                        <div className="only-heading">
                          <span className="heading">
                            Eco-friendly detergents
                          </span>
                        </div>
                      </div>
                      <hr />
                      <div className="service-subdiv">
                        <div className="only-heading">
                          <span className="heading">
                            Gently onyour skin & clothes
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {selectedService === "2" && window.innerWidth > 768 && (
                <div>
                  <img alt="" src={washPressSelected} />
                  <div className="service-para">
                    <p>
                      Schedule your washing or pressing services as per your
                      convenience. We will take it for there, Hassel free and
                      Simple!
                      <br />
                      <br />
                      Just be ready with your Laundry or Keep it outside your
                      door (as per your convenience), we will be right there
                      with our eco-friendly laundry beg. We will grab away all
                      the dirty clothes from your home.
                      <br />
                      <br />
                      Our experts, with the help of our state of art machinery
                      ensure that your clothes come back looking clean, tidier
                      and fresh every time. Your clothes will be delivered at
                      your door step on time and as fresh as daisy.
                    </p>
                    <div>
                      <h5>
                        This bag size is 72*50 cm, could hold upto laundry equal
                        to 2 full laundry loads at home.
                      </h5>
                      <div className="service-subdiv">
                        <span style={{ color: "rgb(30, 30, 93)" }}>
                          Information
                        </span>
                        <span
                          className="price-tag"
                          style={{ fontWeight: "bolder", color: "#424194" }}
                        >
                          from AED 5
                        </span>
                      </div>
                      <hr />
                      <div className="service-subdiv">
                        <div>
                          <span className="heading">
                            Color split accordingly
                          </span>
                          <span className="sub-heading">No color bleeds</span>
                        </div>
                        <img alt="" src={washPress1} />
                      </div>
                      <hr />
                      <div className="service-subdiv">
                        <div>
                          <span className="heading">
                            Clothes washed separately
                          </span>
                          <span className="sub-heading">
                            No mixed with other customers
                          </span>
                        </div>
                        <img alt="" src={washPress2} />
                      </div>
                      <hr />
                      <div className="service-subdiv">
                        <div className="only-heading">
                          <span className="heading">
                            Eco-friendly detergents
                          </span>
                        </div>
                      </div>
                      <hr />
                      <div className="service-subdiv">
                        <div className="only-heading">
                          <span className="heading">
                            Gently onyour skin & clothes
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {selectedService === "4" && window.innerWidth > 768 && (
                <div>
                  <img alt="" src={cleanPressSelected} />
                  <div className="service-para">
                    <p>
                      Schedule your washing or pressing services as per your
                      convenience. We will take it for there, Hassel free and
                      Simple!
                      <br />
                      <br />
                      Just be ready with your Laundry or Keep it outside your
                      door (as per your convenience), we will be right there
                      with our eco-friendly laundry beg. We will grab away all
                      the dirty clothes from your home.
                      <br />
                      <br />
                      Our experts, with the help of our state of art machinery
                      ensure that your clothes come back looking clean, tidier
                      and fresh every time. Your clothes will be delivered at
                      your door step on time and as fresh as daisy.
                    </p>
                    <div>
                      <div className="service-subdiv">
                        <span style={{ color: "rgb(30, 30, 93)" }}>
                          Information
                        </span>
                        <span
                          className="price-tag"
                          style={{ fontWeight: "bolder", color: "#f66a78" }}
                        >
                          from AED 11
                        </span>
                      </div>
                      <hr />
                      <div className="service-subdiv">
                        <div>
                          <span className="heading">
                            Color split accordingly
                          </span>
                          <span className="sub-heading">No color bleeds</span>
                        </div>
                        <img alt="" src={cleanPress1} />
                      </div>
                      <hr />
                      <div className="service-subdiv">
                        <div>
                          <span className="heading">
                            Clothes washed separately
                          </span>
                          <span className="sub-heading">
                            No mixed with other customers
                          </span>
                        </div>
                        <img alt="" src={cleanPress2} />
                      </div>
                      <hr />
                      <div className="service-subdiv">
                        <div className="only-heading">
                          <span className="heading">
                            Eco-friendly detergents
                          </span>
                        </div>
                      </div>
                      <hr />
                      <div className="service-subdiv">
                        <div className="only-heading">
                          <span className="heading">
                            Gently onyour skin & clothes
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {selectedService === "3" && window.innerWidth > 768 && (
                <div>
                  <img alt="WashOn Laundry: Steam Press" src={pressOnlySelected} />
                  <div className="service-para">
                    <p>
                      Schedule your washing or pressing services as per your
                      convenience. We will take it for there, Hassel free and
                      Simple!
                      <br />
                      <br />
                      Just be ready with your Laundry or Keep it outside your
                      door (as per your convenience), we will be right there
                      with our eco-friendly laundry beg. We will grab away all
                      the dirty clothes from your home.
                      <br />
                      <br />
                      Our experts, with the help of our state of art machinery
                      ensure that your clothes come back looking clean, tidier
                      and fresh every time. Your clothes will be delivered at
                      your door step on time and as fresh as daisy.
                    </p>
                    <div>
                      <div className="service-subdiv">
                        <span style={{ color: "rgb(30, 30, 93)" }}>
                          Information
                        </span>
                        <span
                          className="price-tag"
                          style={{ fontWeight: "bolder", color: "#7d7fac" }}
                        >
                          from AED 4
                        </span>
                      </div>
                      <hr />
                      <div className="service-subdiv">
                        <div>
                          <span className="heading">
                            Color split accordingly
                          </span>
                          <span className="sub-heading">No color bleeds</span>
                        </div>
                        <img alt="WashOn Laundry: No color bleeds" src={pressOnly1} />
                      </div>
                      <hr />
                      <div className="service-subdiv">
                        <div>
                          <span className="heading">
                            Clothes washed separately
                          </span>
                          <span className="sub-heading">
                            No mixed with other customers
                          </span>
                        </div>
                        <img alt="WashOn Laundry: Clothese washed seperately" src={pressOnly2} />
                      </div>
                      <hr />
                      <div className="service-subdiv">
                        <div className="only-heading">
                          <span className="heading">
                            Eco-friendly detergents
                          </span>
                        </div>
                      </div>
                      <hr />
                      <div className="service-subdiv">
                        <div className="only-heading">
                          <span className="heading">
                            Gently onyour skin & clothes
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {window.innerWidth > 768 &&
              (!this.isLoggedIn() ? (
                <div className="book-btn" onClick={this.props.setBookOrder}>
                  BOOK NOW
                </div>
              ) : (
                <Link to="/booking">
                  <div className="book-btn" onClick={this.props.setBookOrder}>
                    BOOK NOW
                  </div>
                </Link>
              ))}
          </div>
          <div className="book-services">
            <h3>Book Services in 3 Easy Steps</h3>
            <div className="steps-container">
              <div>
                <img alt="WashOn Laundry: Home Pickup" src={step1}></img>
                <h3>Request for Pick-up</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `Schedule your washing or pressing services as per your convenience. <br /> 
									We will take it for there,<br />
									Hassel free and Simple!`,
                  }}
                ></div>
              </div>
              <div>
                <img alt="WashOn Laundry: Laundry near me" src={step2}></img>
                <h3>Pick Up</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `Just be ready with your Laundry or Keep it outside your door (as per your convenience), we will be right there with our eco-friendly laundry bag. <br />
								We will grab away all the dirty clothes from your home.`,
                  }}
                ></div>
              </div>
              <div>
                <img alt="WashOn Laundry: Drycleaning" src={step3}></img>
                <h3>Cleaning/Washing/Pressing</h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: `Our experts, with the help of our state of art
									machinery ensure that your clothes come back
									looking clean, tidier and fresh every time. <br /> 
									Your clothes will be delivered at your door step
									on time and as fresh as daisy.`,
                  }}
                ></div>
              </div>
            </div>
            {!this.isLoggedIn() ? (
              <div className="book-btn" onClick={this.props.setBookOrder}>
                BOOK NOW
              </div>
            ) : (
              <Link to="/booking">
                <div className="book-btn" onClick={this.props.setBookOrder}>
                  BOOK NOW
                </div>
              </Link>
            )}
          </div>
          <div className="features-container">
            <h3>Our Features</h3>
            <div className="features-tab">
              <div className="feature-subtab">
                <img alt="WashOn Laundry: Trusted Professionals" src={feature1} />
                <div>
                  <h4>Trusted Professionals</h4>
                  <p>
                    Professional, well-trained, reliable, friendly curriers and
                    workers. We have a thorough screening process before we hire
                    our workers.
                  </p>
                </div>
              </div>
              <div className="feature-subtab">
                <img alt="WashOn Laundry: No Hidden Cost" src={feature2} />
                <div>
                  <h4>No Hidden Cost</h4>
                  <p>
                    No additional charges or hidden cost. You only pay for what
                    you ordered!
                  </p>
                </div>
              </div>
              <div className="feature-subtab">
                <img alt="WashOn Laundry: Same Day Availability" src={feature3} />
                <div>
                  <h4>Same Day Availability</h4>
                  <p>
                    Booking takes less than 60 seconds! And you can schedule for
                    as early as today
                  </p>
                </div>
              </div>
              <div className="feature-subtab">
                <img alt="WashOn Laundry: 24/7 Customer service"  src={feature4} />
                <div>
                  <h4>Customer Services</h4>
                  <p>
                    We are here to help you with everything related cleaning
                    services. 24/7 call center at your service!
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="download-app">
            <div className="download-text">
              <h3>Download WashOn APP!</h3>
              <p>
                Download our app to book and manage your appointments with a few
                taps, view your profile and see real time status of your
                bookings.{" "}
              </p>
              <div style={window.innerWidth < 768 ? { display: "flex" } : {}}>
                <a href="http://onelink.to/p3c5wk">
                  <img  alt="WashOn Laundry: Play Store" 
                    style={{ marginRight: "10px" }}
                    src={downloadGoogleImg}
                  />
                </a>
                <a href="http://onelink.to/p3c5wk">
                  <img alt="WashOn Laundry: App Store" src={downloadAppleImg} />
                </a>
              </div>
            </div>
            {window.innerWidth < 768 && <img fetchpriority="low" alt="WashOn Laundry: Download App" src={downloadApp} />}
            {window.innerWidth > 768 && (
              <img
                fetchpriority="low" alt="WashOn Laundry: WashOn App"
                style={{ position: "absolute", top: "-19%", right: "28%" }}
                src={phone1}
              />
            )}
            {window.innerWidth > 768 && (
              <img
                fetchpriority="low" style={{ position: "absolute", top: "3%", right: "13%" }}
                alt="WashOn Laundry: App"
                src={phone2}
              />
            )}
          </div>
          <div className="features-container">
            <h3>Our Customers Say</h3>
            <div className="slider-container">
              <Slider {...settings}>
                <div>
                  <img alt="WashOn Laundry: Customer Feedback" src={profilePic} />
                  <h5 style={{ color: "rgb(74, 91, 185)" }}>
                    Samantha kanonji
                  </h5>
                  <p>
                    perfect washing company they washed my carpet and i loved it
                    since i have kids at home .. the odour was perfect and so
                    soft highly recommended.{" "}
                  </p>
                </div>
                <div>
                  <img alt="WashOn Laundry: Customer Feedback" src={profilePic} />
                  <h5 style={{ color: "rgb(74, 91, 185)" }}>
                    Samantha kanonji
                  </h5>
                  <p>
                    perfect washing company they washed my carpet and i loved it
                    since i have kids at home .. the odour was perfect and so
                    soft highly recommended{" "}
                  </p>
                </div>
                <div>
                  <img fetchpriority="low" alt="WashOn Laundry: Customer Feedback" src={profilePic} />
                  <h5>Samantha kanonji</h5>
                  <p>
                    perfect washing company they washed my carpet and i loved it
                    since i have kids at home .. the odour was perfect and so
                    soft highly recommended{" "}
                  </p>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
