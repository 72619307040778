import React, { Component } from "react";
import {
  withGoogleMap,
  GoogleMap,
  withScriptjs,
  Marker,
} from "react-google-maps";
import Geocode from "react-geocode";
import Autocomplete from "react-google-autocomplete";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import addressPin from "../images/address-pin-location.png";

const TextFieldNew = withStyles({
  root: {
    margin: 18,
    width: window.innerWidth > 768 ? "44%" : "90%",
  },
  label: {
    fontSize: 18,
  },
})(TextField);

Geocode.setApiKey("AIzaSyDbk3_hD66WoJHjg7uEscNh3k-D6ldpwtk");
Geocode.enableDebug();

const StyledButton = withStyles({
  root: {
    width: 139,
    height: 48,
    marginRight: 18,
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);

const StyledButton2 = withStyles({
  root: {
    width: 160,
    height: 48,
    marginRight: 18,
  },
  label: {
    textTransform: "capitalize",
  },
})(Button);

class Address extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: "",
      city: "",
      area: "",
      state: "",
      mapPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng,
      },
      markerPosition: {
        lat: this.props.center.lat,
        lng: this.props.center.lng,
      },
      flatNoError: false,
    };
  }
  /**
   * Get the current address from the default map position and set those values in the state
   */

  initialize = () => {
    Geocode.fromLatLng(
      this.state.mapPosition.lat,
      this.state.mapPosition.lng
    ).then(
      (response) => {
        const placeId = response.results[0].place_id;
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          state = this.getState(addressArray);

        this.setState({
          address: address ? address : "",
          area: area ? area : "",
          city: city ? city : "",
          state: state ? state : "",
          placeId: placeId ? placeId : "",
        });
      },
      (error) => {
        console.error(error);
      }
    );
    this.setState({ showMap: true, disabled: false });
  };
  componentDidMount() {
    if (this.props.profileData && this.props.profileData.address) {
      const {
        place_name,
        unit_no,
        area,
        building_name,
        source_uuid,
        id,
        street_address,
      } = this.props.profileData.address;
      this.setState({
        address: place_name,
        selfArea: area,
        placeId: source_uuid,
        flatNo: unit_no,
        building: building_name,
        addressId: id,
        disabled: true,
        selfStreet: street_address,
      });
    } else
      Geocode.fromLatLng(
        this.state.mapPosition.lat,
        this.state.mapPosition.lng
      ).then(
        (response) => {
          const placeId = response.results[0].place_id;
          const address = response.results[0].formatted_address,
            addressArray = response.results[0].address_components,
            city = this.getCity(addressArray),
            area = this.getArea(addressArray),
            state = this.getState(addressArray);

          this.setState({
            address: address ? address : "",
            area: area ? area : "",
            city: city ? city : "",
            state: state ? state : "",
            placeId: placeId ? placeId : "",
          });
        },
        (error) => {
          console.error(error);
        }
      );
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.profileData !== nextProps.profileData) {
      const { place_name, unit_no, area, building_name, source_uuid } =
        this.props.profileData;
      this.setState({
        address: place_name,
        selfArea: area,
        placeId: source_uuid,
        flatNo: unit_no,
        building: building_name,
      });
    }
  }

  /**
   * Component should only update ( meaning re-render ), when the user selects the address, or drags the pin
   *
   * @param nextProps
   * @param nextState
   * @return {boolean}
   */
  shouldComponentUpdate(nextProps, nextState) {
    if (
      this.state.address !== nextState.address ||
      this.state.city !== nextState.city ||
      this.state.area !== nextState.area ||
      this.state.state !== nextState.state
    ) {
      return true;
    } else if (this.props.center.lat === nextProps.center.lat) {
      return false;
    }
  }
  /**
   * Get the city and set the city input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getCity = (addressArray) => {
    let city = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (
        addressArray[i].types[0] &&
        "administrative_area_level_2" === addressArray[i].types[0]
      ) {
        city = addressArray[i].long_name;
        return city;
      }
    }
  };
  /**
   * Get the area and set the area input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getArea = (addressArray) => {
    let area = "";
    for (let i = 0; i < addressArray.length; i++) {
      if (addressArray[i].types[0]) {
        for (let j = 0; j < addressArray[i].types.length; j++) {
          if (
            "sublocality_level_1" === addressArray[i].types[j] ||
            "locality" === addressArray[i].types[j]
          ) {
            area = addressArray[i].long_name;
            return area;
          }
        }
      }
    }
  };
  /**
   * Get the address and set the address input value to the one selected
   *
   * @param addressArray
   * @return {string}
   */
  getState = (addressArray) => {
    let state = "";
    for (let i = 0; i < addressArray.length; i++) {
      for (let i = 0; i < addressArray.length; i++) {
        if (
          addressArray[i].types[0] &&
          "administrative_area_level_1" === addressArray[i].types[0]
        ) {
          state = addressArray[i].long_name;
          return state;
        }
      }
    }
  };
  /**
   * And function for city,state and address input
   * @param event
   */
  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  /**
   * This Event triggers when the marker window is closed
   *
   * @param event
   */
  onInfoWindowClose = (event) => {};

  /**
   * When the marker is dragged you get the lat and long using the functions available from event object.
   * Use geocode to get the address, city, area and state from the lat and lng positions.
   * And then set those values in the state.
   *
   * @param event
   */
  onMarkerDragEnd = (event) => {
    let newLat = event.latLng.lat(),
      newLng = event.latLng.lng();

    Geocode.fromLatLng(newLat, newLng).then(
      (response) => {
        const placeId = response.results[0].place_id;
        const address = response.results[0].formatted_address,
          addressArray = response.results[0].address_components,
          city = this.getCity(addressArray),
          area = this.getArea(addressArray),
          state = this.getState(addressArray);
        this.setState({
          address: address ? address : "",
          area: area ? area : "",
          city: city ? city : "",
          state: state ? state : "",
          markerPosition: {
            lat: newLat,
            lng: newLng,
          },
          placeId: placeId ? placeId : "",
        });
      },
      (error) => {
        console.error(error);
      }
    );
  };

  /**
   * When the user types an address in the search box
   * @param place
   */
  onPlaceSelected = (place) => {
    const placeId = place.place_id;
    const address = place.formatted_address,
      addressArray = place.address_components,
      city = this.getCity(addressArray),
      area = this.getArea(addressArray),
      state = this.getState(addressArray),
      latValue = place.geometry.location.lat(),
      lngValue = place.geometry.location.lng();
    // Set these values in the state.
    this.setState({
      address: address ? address : "",
      area: area ? area : "",
      city: city ? city : "",
      state: state ? state : "",
      markerPosition: {
        lat: latValue,
        lng: lngValue,
      },
      mapPosition: {
        lat: latValue,
        lng: lngValue,
      },
      placeId: placeId ? placeId : "",
    });
  };

  setAddress = () => {
    if (!this.state.flatNo || !this.state.address) {
      this.setState({ flatNoError: true });
      this.forceUpdate();
    } else {
      if (!this.state.flatNo || !this.state.address) {
        this.setState({ flatNoError: true });
        this.forceUpdate();
      } else if (!this.state.building || !this.state.address) {
        this.setState({ buildingError: true });
        this.forceUpdate();
      } else if (!this.state.selfArea || !this.state.address) {
        this.setState({ areaError: true });
        this.forceUpdate();
      } else {
        let location = {};
        if (this.state.addressId && !this.state.showMap) {
          location = {
            id: this.state.addressId,
          };
        } else
          location = {
            latitude: this.state.markerPosition.lat,
            longitude: this.state.markerPosition.lng,
            place_name: this.state.area,
            unit_no: this.state.flatNo,
            building_name: this.state.building,
            area: this.state.selfArea,
            source_uuid: this.state.placeId,
            street_address: this.state.selfStreet,
          };
        this.props.setAddress(
          this.state.markerPosition,
          this.state.flatNo,
          this.state.address,
          this.state.city,
          this.state.place,
          location,
          this.state.directions
        );
        this.props.handleNext();
      }
    }
  };

  render() {
    const AsyncMap = withScriptjs(
      withGoogleMap((props) => (
        <GoogleMap
          google={this.props.google}
          defaultZoom={this.props.zoom}
          defaultCenter={{
            lat: this.state.mapPosition.lat,
            lng: this.state.mapPosition.lng,
          }}
        >
          {/* InfoWindow on top of marker */}

          {/*Marker*/}
          <Marker
            google={this.props.google}
            name={"Dolores park"}
            draggable={true}
            onDragEnd={this.onMarkerDragEnd}
            position={{
              lat: this.state.markerPosition.lat,
              lng: this.state.markerPosition.lng,
            }}
          />
          <Marker />
          {/* For Auto complete Search Box */}
          <Autocomplete
            style={{
              width: "100%",
              height: "40px",
              paddingLeft: "16px",
              marginTop: "2px",
              marginBottom: "500px",
            }}
            onPlaceSelected={this.onPlaceSelected}
            componentRestrictions={{ country: "ae" }}
          />
        </GoogleMap>
      ))
    );
    let map;
    if (this.props.center.lat !== undefined) {
      map = (
        <div>
          <AsyncMap
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDbk3_hD66WoJHjg7uEscNh3k-D6ldpwtk&libraries=places"
            loadingElement={<div style={{ height: `100%` }} />}
            containerElement={
              <div
                style={{ height: this.props.height, paddingBottom: "80px" }}
              />
            }
            mapElement={<div style={{ height: `100%` }} />}
          />
        </div>
      );
    } else {
      map = <div style={{ height: this.props.height }} />;
    }
    return (
      <div className="map-container">
        {window.innerWidth < 768 && (
          <div>
            <p>Address</p>
          </div>
        )}
        {this.props.profileData &&
        this.props.profileData.address &&
        !this.state.showMap ? (
          <div style={{ textAlign: "left" }}>
            <StyledButton2
              variant="contained"
              color="primary"
              onClick={this.initialize}
            >
              Enter New Address
            </StyledButton2>
          </div>
        ) : (
          map
        )}
        <div className="address-detail-container">
          <div>
            <hr />
            <div
              style={{
                display: "flex",
                marginLeft: "5%",
                alignItems: "center",
              }}
            >
              <div style={{ marginRight: "16px" }}>
                <img alt="WashOn Laundry: Address Pin" src={addressPin} />
              </div>
              <div style={{ textAlign: "left" }}>
                <div style={{ fontSize: "16px", color: "rgb(30, 30, 93)" }}>
                  Pin Location
                </div>
                <div style={{ fontSize: "16px", color: "rgb(168, 176, 201)" }}>
                  {this.state.address}
                </div>
              </div>
            </div>
            <hr />
          </div>
          <div style={{ textAlign: "left", paddingBottom: "40px" }}>
            <TextFieldNew
              error={this.state.flatNoError}
              InputProps={{ style: { fontSize: 18 } }}
              InputLabelProps={{ style: { fontSize: 16 } }}
              required
              disabled={this.state.disabled}
              key={this.state.flatNo}
              id="standard-size-normal"
              size="normal"
              label="Flat/Villa No"
              defaultValue={this.state.flatNo}
              helperText={this.state.flatNoError && "This field is required"}
              onChange={(e) => this.setState({ flatNo: e.target.value })}
              placeHolder="Eg. ‘204’, ‘1A’"
            />

            <TextFieldNew
              error={this.state.buildingError}
              InputProps={{ style: { fontSize: 18 } }}
              InputLabelProps={{ style: { fontSize: 16 } }}
              required
              key={this.state.building}
              disabled={this.state.disabled}
              id="standard-size-normal"
              size="normal"
              label="Building/Villa"
              defaultValue={this.state.building}
              helperText={this.state.buildingError && "This field is required"}
              onChange={(e) => this.setState({ building: e.target.value })}
              placeHolder="Eg. Elite Building"
            />
            <TextFieldNew
              InputProps={{ style: { fontSize: 18 } }}
              InputLabelProps={{ style: { fontSize: 16 } }}
              id="standard-size-normal"
              key={this.state.selfStreet}
              disabled={this.state.disabled}
              size="normal"
              label="Street"
              defaultValue={this.state.selfStreet}
              onChange={(e) => this.setState({ selfStreet: e.target.value })}
            />

            <TextFieldNew
              error={this.state.areaError}
              InputProps={{ style: { fontSize: 18 } }}
              InputLabelProps={{ style: { fontSize: 16 } }}
              required
              disabled={this.state.disabled}
              id="standard-size-normal"
              size="normal"
              label="Area"
              key={this.state.selfArea}
              defaultValue={this.state.selfArea}
              helperText={this.state.areaError && "This field is required"}
              onChange={(e) => this.setState({ selfArea: e.target.value })}
            />

            <TextFieldNew
              InputProps={{ style: { fontSize: 18 } }}
              InputLabelProps={{ style: { fontSize: 16 } }}
              id="standard-size-normal"
              size="normal"
              label="Cleaning instruction"
              placeHolder="Add directions for our courier"
              value={this.state.directions}
              onChange={(e) => this.setState({ directions: e.target.value })}
            />
          </div>
        </div>
        <StyledButton
          variant="contained"
          color="primary"
          onClick={this.setAddress}
        >
          {this.props.activeStep === this.props.steps.length - 1
            ? "Finish"
            : "Next"}
        </StyledButton>
      </div>
    );
  }
}

export default Address;
