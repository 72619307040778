import React from "react";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";
import { setSlotsData, setProfileData } from "../actions";

const TextFieldNew = withStyles({
  root: {
    margin: "18px 0px",
    width: "90%",
    fontSize: 14,
  },
  input: {
    fontSize: 14,
  },
  label: {
    fontSize: 14,
  },
})(TextField);

class Profile extends React.Component {
  state = {
    address: [],
  };

  componentDidMount() {
    document.body.style.backgroundColor = "rgb(249, 249, 251)";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("userToken")}`
    );
    let requestOptions2 = {
      // credentials: 'include',
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch("https://api.washon.ae/profile/get", requestOptions2)
      .then((response) => {
        if (response.status === 200) return response.json();
        else return false;
      })
      .then((result) => {
        if (result) {
          this.props.setProfileData(result.data);
        }
      })
      .catch((error) => console.error(error));
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.profileData !== nextProps.profileData) {
      const { name, phone_number, email, address } = nextProps.profileData;
      this.setState({
        name,
        phone_number,
        email,
        address: [address],
      });
    }
  }

  updateDetails = () => {
    const { name, phone_number, email } = this.state;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("userToken")}`
    );
    let profileData = {
      name,
      phone_number,
      email,
    };
    var raw = JSON.stringify(profileData);

    let requestOptions2 = {
      method: "PUT",
      headers: myHeaders,
      redirect: "follow",
      body: raw,
    };
    fetch("http://api.washon.ae/profile", requestOptions2)
      .then((response) => {
        if (response.status === 200) return response.json();
        else return false;
      })
      .catch((error) => console.error(error));
  };

  render() {
    return (
      <div className="profile-container">
        <h3>Hello {this.props.profileData.name || "User"}</h3>
        <div className="sub-container">
          <div className="personal-detail-container white-box">
            <h3>Personal</h3>
            <TextFieldNew
              error={this.state.nameError}
              InputProps={{ style: { fontSize: 14, color: "rgb(30, 30, 93)" } }}
              InputLabelProps={{
                style: { fontSize: 12, color: "rgb(168, 176, 201)" },
              }}
              required
              disabled={this.state.disabled}
              key={this.state.name}
              id="standard-size-normal"
              size="normal"
              label="Your Name"
              defaultValue={this.state.name}
              helperText={this.state.nameError && "This field is required"}
              onChange={(e) => this.setState({ name: e.target.value })}
              placeHolder="Enter your name"
            />
            <TextFieldNew
              error={this.state.nameError}
              InputProps={{ style: { fontSize: 14, color: "rgb(30, 30, 93)" } }}
              InputLabelProps={{
                style: { fontSize: 12, color: "rgb(168, 176, 201)" },
              }}
              required
              disabled={this.state.disabled}
              key={this.state.phone_number}
              id="standard-size-normal"
              size="normal"
              label="Your Phone Number"
              defaultValue={this.state.phone_number}
              helperText={this.state.nameError && "This field is required"}
              onChange={(e) => this.setState({ phone_number: e.target.value })}
              placeHolder="Enter your name"
            />
            <TextFieldNew
              error={this.state.nameError}
              InputProps={{ style: { fontSize: 14, color: "rgb(30, 30, 93)" } }}
              InputLabelProps={{
                style: { fontSize: 12, color: "rgb(168, 176, 201)" },
              }}
              required
              disabled={this.state.disabled}
              key={this.state.email}
              id="standard-size-normal"
              size="normal"
              label="Your Email"
              defaultValue={this.state.email}
              helperText={this.state.nameError && "This field is required"}
              onChange={(e) => this.setState({ email: e.target.value })}
              placeHolder="Enter your name"
            />

            <span
              style={{
                fontSize: "14px",
                color: "rgb(94, 129, 244)",
                textDecoration: "underline",
                fontWeight: "bold",
                marginTop: "30px",
              }}
              onClick={this.updateDetails}
            >
              Update Details
            </span>
          </div>
          {this.props.profileData && this.props.profileData.address && (
            <div className="address-container white-box">
              <h3>Address</h3>
              {this.state.address.map((data) => (
                <div className="white-box2">
                  <h5>{this.props.profileData.address.unit_no}</h5>
                  <p>{this.props.profileData.address.place_name}</p>
                </div>
              ))}
              {/* <h5 style={{color: "rgb(94, 129, 244)", textDecoration: "underline", fontWeight: "bold", marginTop: "30px"}}>Add New Address</h5> */}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    slotsData: state.slotsData,
    profileData: state.profileData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSlotsData: (data) => {
      dispatch(setSlotsData(data));
    },
    setProfileData: (data) => {
      dispatch(setProfileData(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
