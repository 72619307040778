import React from "react";
import Button from "@material-ui/core/Button";

export default class Footer extends React.Component {
  state = {
    pickUpDate: 0,
    pickUpTime: 0,
    dropOffTime: 0,
    dropOffDate: 0,
    timeSlot: {},
    dropoffSlots: [],
  };

  componentDidMount() {
    if (this.props.data) {
      this.selectOption("pickUpDate", 0, this.props.data[0].time_slot_list[0]);
      this.selectOption(
        "pickUpTime",
        0,
        this.props.data[this.state.pickUpDate].time_slot_list[
          this.state.pickUpTime
        ]
      );
      this.setState(
        {
          timeSlot:
            this.props.data[this.state.pickUpDate].time_slot_list[
              this.state.pickUpTime
            ],
        },
        () => {
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Accept", "application/json");
          myHeaders.append(
            "Authorization",
            `Bearer ${localStorage.getItem("userToken")}`
          );
          var raw = JSON.stringify({
            pickup_time: this.state.timeSlot.time_string,
          });
          var requestOptions = {
            // credentials: 'include',
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
            body: raw,
          };
          fetch("https://api.washon.ae/booking/delivery/slots", requestOptions)
            .then((response) => response.json())
            .then((result) => {
              this.setState({
                dropoffSlots: result.data.slots,
              });

              this.selectOption(
                "dropOffTime",
                0,
                null,
                result.data.slots[0].time_slot_list[0],
                result.data.slots
              );
              this.selectOption("dropOffDate", 0);
            })
            .catch((error) => console.error("error", error));
        }
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.data && !this.props.data) {
      this.selectOption("pickUpDate", 0, nextProps.data[0].time_slot_list[0]);
      this.selectOption(
        "pickUpTime",
        0,
        nextProps.data[this.state.pickUpDate].time_slot_list[
          this.state.pickUpTime
        ]
      );
      this.setState(
        {
          timeSlot:
            nextProps.data[this.state.pickUpDate].time_slot_list[
              this.state.pickUpTime
            ],
        },
        () => {
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Accept", "application/json");
          myHeaders.append(
            "Authorization",
            `Bearer ${localStorage.getItem("userToken")}`
          );
          var raw = JSON.stringify({
            pickup_time: this.state.timeSlot.time_string,
          });
          var requestOptions = {
            // credentials: 'include',
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
            body: raw,
          };
          fetch("https://api.washon.ae/booking/delivery/slots", requestOptions)
            .then((response) => response.json())
            .then((result) => {
              this.setState({
                dropoffSlots: result.data.slots,
              });

              this.selectOption(
                "dropOffTime",
                0,
                null,
                result.data.slots[0].time_slot_list[0],
                result.data.slots
              );
              this.selectOption("dropOffDate", 0);
            })
            .catch((error) => console.error("error", error));
        }
      );
      this.props.setValue(
        "pickUpTime",
        0,
        nextProps.data[this.state.pickUpDate].time_slot_list[
          this.state.pickUpTime
        ]
      );
    }
  }

  selectOption = (type, value, timeSlot, deliveryTimeSlot, deliverySlots) => {
    this.setState({
      [type]: value,
    });
    if (type === "pickUpDate" || type === "pickUpTime") {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Accept", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("userToken")}`
      );
      var raw = JSON.stringify({ pickup_time: timeSlot.time_string });
      var requestOptions = {
        // credentials: 'include',
        method: "POST",
        headers: myHeaders,
        redirect: "follow",
        body: raw,
      };
      fetch("https://api.washon.ae/booking/delivery/slots", requestOptions)
        .then((response) => response.json())
        .then((result) => {
          this.setState({
            dropoffSlots: result.data.slots,
          });

          this.selectOption(
            "dropOffTime",
            0,
            null,
            result.data.slots[0].time_slot_list[0],
            result.data.slots
          );
          this.selectOption("dropOffDate", 0);
        })
        .catch((error) => console.error("error", error));
    }
    this.props.setValue(type, value, timeSlot, deliveryTimeSlot, deliverySlots);
  };
  render() {
    return (
      <div className="date-time-container">
        <div>
          <h4>PICK UP TIME</h4>
          <hr />
          <div
            style={{
              overflowX: "auto",
              whiteSpace: "nowrap",
              paddingBottom: "20px",
            }}
          >
            <div className="date-time-subcontainer">
              {this.props.data &&
                this.props.data.map((slot, i) => {
                  return (
                    <div
                      className={`date-day ${
                        this.state.pickUpDate === i ? "active" : ""
                      }`}
                      onClick={() =>
                        this.selectOption(
                          "pickUpDate",
                          i,
                          this.props.data[i].time_slot_list[0]
                        )
                      }
                    >
                      <span className="date">{slot.day}</span>
                      <span className="day">{slot.date}</span>
                    </div>
                  );
                })}
            </div>
            <div className="date-time-subcontainer">
              {this.props.data &&
                this.props.data[this.state.pickUpDate].time_slot_list.map(
                  (timeSlot, i) => {
                    return (
                      <div
                        className={`date-day ${
                          this.state.pickUpTime === i ? "active" : ""
                        }`}
                        onClick={() =>
                          this.selectOption("pickUpTime", i, timeSlot)
                        }
                      >
                        <span className="date">Between</span>
                        <span className="day">{timeSlot.from}</span>
                        <span className="day">{timeSlot.to}</span>
                      </div>
                    );
                  }
                )}
            </div>
          </div>
        </div>
        <div>
          <h4>DROP OFF TIME</h4>
          <hr />
          <div
            style={{
              overflowX: "auto",
              whiteSpace: "nowrap",
              paddingBottom: "20px",
            }}
          >
            <div className="date-time-subcontainer">
              {this.state.dropoffSlots.length
                ? this.state.dropoffSlots.map((slot, i) => {
                    return (
                      <div
                        className={`date-day ${
                          this.state.dropOffDate === i ? "active" : ""
                        }`}
                        onClick={() => this.selectOption("dropOffDate", i)}
                      >
                        <span className="date">{slot.day}</span>
                        <span className="day">{slot.date}</span>
                      </div>
                    );
                  })
                : null}
            </div>
            <div className="date-time-subcontainer">
              {this.state.dropoffSlots.length
                ? this.state.dropoffSlots[
                    this.state.dropOffDate
                  ].time_slot_list.map((timeSlot, i) => {
                    return (
                      <div
                        className={`date-day ${
                          this.state.dropOffTime === i ? "active" : ""
                        }`}
                        onClick={() =>
                          this.selectOption(
                            "dropOffTime",
                            i,
                            null,
                            timeSlot,
                            this.state.dropoffSlots
                          )
                        }
                      >
                        <span className="date">Between</span>
                        <span className="day">{timeSlot.from}</span>
                        <span className="day">{timeSlot.to}</span>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
        <Button
          variant="contained"
          color="primary"
          onClick={this.props.handleNext}
        >
          {this.props.activeStep === this.props.steps.length - 1
            ? "Finish"
            : "Next"}
        </Button>
      </div>
    );
  }
}
