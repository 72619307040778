import React from "react";

import { Container, Grid } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import Location from "../components/Location";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { areas } from "../data/areas";


const useStyles = makeStyles((theme) => ({
  blogsContainer: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  blogTitle: {
    paddingBottom: theme.spacing(5),
    fontWeight: 800,
  },
}));

export default function LocationDetails() {
  const classes = useStyles();
  return (
    <div>
    <Helmet>
    <title>Dubai Laundry - WashOn | Quick, Easy, Professional Service </title>    
    <meta property="title" content="Dubai Laundry Services - WashOn | Areas we serve" />
    <meta name="description" content="WashOn Laundry provides quick, easy, and professional laundry services in Dubai. We cover almost all Dubai with Free Pickup and delivery." />
     <meta name="keywords" content="Best laundry service in dubai, Laundry, Stroller cleaning in Dubai, Laundry service near me, Dry Cleaning in Dubai, Shoe cleaning in Dubai, Laundry Blogs, Laundry Services, Carpet cleaner, laundry near me"/>
    <meta property="og:locale" content="en_US" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Dubai Laundry Services - WashOn | Areas we serve" />
    <meta property="og:description" content="WashOn Laundry provides quick, easy, and professional laundry services in Dubai. We cover almost all Dubai with Free Pickup and delivery." />
    <meta name="og:keywords" content="Best laundry service in dubai, Laundry, Stroller cleaning in Dubai, Laundry service near me, Dry Cleaning in Dubai, Shoe cleaning in Dubai, Laundry Blogs, Laundry Services, Carpet cleaner, laundry near me"/>
    <meta property="og:url" content="https://www.washon.ae/locations" />
    <link rel="canonical" href="https://www.washon.ae/locations" />
    <meta property="og:site_name" content="WashOn Laundry Services" />
    <meta property="og:image" content="https://washon-image.s3.ap-south-1.amazonaws.com/site-image/washon-white-bg.png" />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:title" content="Dubai Laundry Services - WashOn | Areas we serve" />
    <meta name="twitter:description" content="WashOn Laundry provides quick, easy, and professional laundry services in Dubai. We cover almost all Dubai with Free Pickup and delivery."/>
    <meta name="twitter:image" content="https://washon-image.s3.ap-south-1.amazonaws.com/site-image/washon-white-bg.png"/>
        {/* Add other head content specific to the about page */}
        </Helmet>

      <Container maxWidth="lg" className={classes.blogsContainer}>
        <h1 style={{ marginBottom: 20, fontSize: 35, color: "rgb(30, 30, 93)"}}>
        WashOn Professional Laundry Services in Dubai | Serving Your Area
        </h1>
        <Grid container spacing={0}>
          {areas.map((a, i) => (
            <Grid item xs={12} sm={4} md={4}>
              <Link to={`/locations/${a.link}`}>
                <Location
                  title={a.title}
                  icon={a.icon}
                  subtitle={a.subtitle}
                />
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}
