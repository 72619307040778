import React from "react";

import { Container, Grid } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import Service from "../components/Service";
import { Link } from "react-router-dom";
import { Helmet } from 'react-helmet';
import { services } from "../data/services";


const useStyles = makeStyles((theme) => ({
  blogsContainer: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
  },
  blogTitle: {
    paddingBottom: theme.spacing(5),
    fontWeight: 800,
  },
}));

export default function ServiceDetails() {
  const classes = useStyles();
  return (
    <div>
    <Helmet>
    <title>Dubai Laundry - WashOn | Quick, Easy, Professional Service </title>    
    <meta property="title" content="Dubai Laundry Services - WashOn | Quick, Easy, Professional Service" />
    <meta name="description" content="WashOn Laundry provides quick, easy, and professional laundry services in Dubai. Enjoy free pickup and clean clothes with minimal hassle." />
     <meta name="keywords" content="Best laundry service in dubai, Laundry, Stroller cleaning in Dubai, Laundry service near me, Dry Cleaning in Dubai, Shoe cleaning in Dubai, Laundry Blogs, Laundry Services, Carpet cleaner, laundry near me"/>
    <meta property="og:locale" content="en_US" />
    <meta property="og:type" content="website" />
    <meta property="og:title" content="Dubai Laundry Services - WashOn | Quick, Easy, Professional Service" />
    <meta property="og:description" content="WashOn Laundry provides quick, easy, and professional laundry services in Dubai. Enjoy doorstep pickup, fast delivery, and fresh, clean clothes with minimal hassle." />
    <meta name="og:keywords" content="Best laundry service in dubai, Laundry, Stroller cleaning in Dubai, Laundry service near me, Dry Cleaning in Dubai, Shoe cleaning in Dubai, Laundry Blogs, Laundry Services, Carpet cleaner, laundry near me"/>
    <meta property="og:url" content="https://www.washon.ae/services" />
    <link rel="canonical" href="https://www.washon.ae/services" />
    <meta property="og:site_name" content="WashOn Laundry Services" />
    <meta property="og:image" content="https://washon-image.s3.ap-south-1.amazonaws.com/site-image/washon-white-bg.png" />
    <meta name="twitter:card" content="summary" />
    <meta name="twitter:title" content="WashOn Services: Laundry, Curtain, Carpet and Shoe Cleaning" />
    <meta name="twitter:description" content="WashOn Laundry Services: WashOn provides high quality laundry, drycleaning, steam press, shoe cleaning, carpet cleaning, curtain cleaning and stroller cleaning services"/>
    <meta name="twitter:image" content="https://washon-image.s3.ap-south-1.amazonaws.com/site-image/washon-white-bg.png"/>
        {/* Add other head content specific to the about page */}
        </Helmet>

      <Container maxWidth="lg" className={classes.blogsContainer}>
        <h1 style={{ marginBottom: 20, fontSize: 35, color: "rgb(30, 30, 93)"}}>
        Dubai Laundry - WashOn | Quick, Easy, Professional Services
        </h1>
        <Grid container spacing={0}>
          {services.map((a, i) => (
            <Grid item xs={12} sm={6} md={6}>
              <Link to={`/services/${a.link}`}>
                <Service
                  title={a.title}
                  icon={a.icon}
                  subtitle={a.subtitle}
                />
              </Link>
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
}
