import React, { useEffect, useState } from "react";
import { cardIcons } from "../utils/CardTypes";
import AddIcon from "../images/add-icon.png";
import { CardNumber, Cvv, ExpiryDate, Frames } from "frames-react";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../css/payment.css";

export default function SavedCards(props) {
  const [state, setState] = React.useState({
    checkedA: true,
    paymentMethod: "Cash",
  });

  const [cardholder, setCardholder] = useState({
    name: "",
  });

  const [cardList, setCardList] = useState([]);
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);
  const [showAddCard, setShowAddCard] = useState(true);
  const [savingCard, setSavingCard] = useState(false);

  const onSelectCard = (i) => {
    setSelectedCardIndex(i);
    props.setPaymentMode(state.paymentMethod, cardList[i]);
  };

  const saveCard = async () => {
    setSavingCard(true);
    if (Frames.isCardValid()) {
      try {
        const res = await Frames.submitCard();
        try {
          // Environment Config
          const response = await fetch(
            `https://api.washon.ae/payment/prod/add`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("userToken")}`,
              },
              redirect: "follow",
              body: JSON.stringify(res),
            }
          );
          const result = await response.json();
          if (result && result.data && result.data.approved) {
            setCardList(result.data.payments);
            setState({ paymentMethod: "AddCard" });
          }
        } catch (e) {
          alert("Something went wrong. Card details could not be added.");
        }
      } catch (e) {
        alert("Something went wrong. Card details could not be added.");
      }
    } else {
      alert("Card details Invalid");
    }
    props.setPaymentMode("AddCard");
    setSavingCard(false);
  };

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("userToken")}`
    );
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(`https://api.washon.ae/payment/get`, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((result) => {
        setCardList(result.data);
      })
      .catch((error) => console.error(error));
  }, []);

  return (
    <div className="modal-container margin-10">
      <strong style={{ fontSize: 20 }}>Select Card</strong>
      {
        <div style={{ marginTop: "20px" }}>
          {cardList && cardList.length > 0 ? (
            cardList.map((card, i) => {
              return (
                card &&
                card.scheme && (
                  <div
                    style={{
                      padding: "10px",
                      boxShadow: "2px 0px 10px #bbb",
                      borderRadius: "5px",
                      color: "#a7b1c9",
                      marginBottom: "15px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    onClick={onSelectCard}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={cardIcons[card.scheme.toLowerCase()]}
                        alt=""
                        style={{
                          width: 44,
                          height: 34,
                          resizeMode: "contain",
                        }}
                      />
                      <span style={{ marginLeft: 20 }}>
                        Credit Card ....{card.last4}
                      </span>
                    </div>
                    {selectedCardIndex === i ? (
                      <div id="tick-mark"></div>
                    ) : null}
                  </div>
                )
              );
            })
          ) : (
            <></>
          )}
        </div>
      }
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          background: "#4A5BB9",
          height: 50,
          padding: 10,
          borderRadius: 5,
          margin: "2% 0 2% 0",
        }}
        onClick={() => setShowAddCard((prev) => !prev)}
      >
        <span style={{ color: "#ffffff" }}>Add Card</span>
        <img
          src={AddIcon}
          alt="add_icon"
          style={{
            height: 24,
            width: 24,
          }}
        />
      </div>
      {showAddCard && (
        <div style={{ textAlign: "center" }}>
          <div
            style={{
              display: "flex",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            <Frames
              config={{
                debug: true,
                publicKey: "pk_cmvjhc2ytfeq3l4atspspd6c6at",
                localization: {
                  cardNumberPlaceholder: "1234 5678 1234 5678",
                  expiryMonthPlaceholder: "MM",
                  expiryYearPlaceholder: "YY",
                  cvvPlaceholder: "CVC",
                },
                cardholder: {
                  name: cardholder.name,
                },
                style: {
                  base: {
                    fontSize: "14px",
                    textAlign: "center",
                  },
                },
              }}
              ready={(e) => {}}
              frameActivated={(e) => {}}
              frameFocus={(e) => {}}
              frameBlur={(e) => {}}
              frameValidationChanged={(e) => {}}
              paymentMethodChanged={(e) => {}}
              cardValidationChanged={(e) => {}}
              cardSubmitted={(e) => {}}
              cardTokenized={(e) => {
                console.log("tokenized card details:", e);
              }}
              cardTokenizationFailed={(e) => {}}
            >
              <div style={{ width: "100%" }}>
                <div style={{ width: "100%" }}>
                  <input
                    style={{
                      backgroundColor: "#EFEFF5",
                      textAlign: "center",
                      borderRadius: 5,
                      height: 60,
                      width: "100%",
                      marginBottom: 10,
                      outline: 0,
                      boxShadow: "none",
                      borderWidth: 0,
                    }}
                    placeholder="Cardholder Name"
                    onChange={(e) => {
                      setCardholder({
                        name: e.target.value,
                      });
                    }}
                  />
                </div>
                <div style={{ width: "100%" }}>
                  <CardNumber style={{ margin: 0 }} />
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: 20,
                    // justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div style={{ width: "50%" }}>
                    <ExpiryDate />
                  </div>
                  <div style={{ width: "50%" }}>
                    <Cvv />
                  </div>
                </div>
              </div>
            </Frames>
          </div>

          {Frames.isCardValid && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#4A5BB9",
                height: 50,
                padding: 10,
                borderRadius: 5,
              }}
              onClick={saveCard}
            >
              {!savingCard ? (
                <span style={{ color: "white" }}>SAVE</span>
              ) : (
                <CircularProgress size={24} color="#fff" />
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
