// Login Credentials :
// Phone Number: +971 (523155409)
// OTP: 1717

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import OtpInput from "react-otp-input";
import ResendButton from "./ResendButton";

import { useHistory } from "react-router-dom";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    color: "rgb(30, 30, 93)",
    minWidth: "300px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    width: window.innerWidth > 768 ? "330px" : "100%",
  },
}))(MuiDialogContent);

export default function CustomizedDialogs(props) {
  const [entered, setEntered] = React.useState(false);
  const [otpVerified, setOtpVerified] = React.useState(false);
  const [otp, setOtp] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [showResendOtp, setShowResendOtp] = React.useState(false);
  const [name, setName] = React.useState("");
  const [title, setTitle] = React.useState("PLEASE VERIFY YOUR NUMBER");
  const [email, setEmail] = React.useState("");
  const [errorName, setError] = React.useState(false);

  let timer = null;

  let history = useHistory();

  const handleClose = () => {
    setEntered(false);
    clearTimeout(timer);
    props.handleCloseDialog();
  };

  const clearOTP = () => {
    setOtp("");
  };

  const handleOtpChange = async (otp) => {
    setOtp(otp);
    if (otp.toString().length === 4) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        user_id: localStorage.getItem("userId"),
        otp: otp.toString(),
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch("https://api.washon.ae/user/login/followup", requestOptions)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else return false;
        })
        .then((result) => {
          if (result) {
            localStorage.setItem("userToken", result.data.user_token);
            if (!result.data.profile_flag) {
              setOtpVerified(true);
              setTitle("Please provide more details");
            } else {
              if (props.bookOrder) {
                history.push("/booking");
              }
              props.loggedIn();
              handleClose();
              setEntered(false);
            }
            setShowResendOtp(false);
            clearTimeout(timer);
          } else {
            alert("Invalid Otp");
          }
        })
        .catch((error) => {
          handleClose();
          setEntered(false);
        });
      clearOTP();
    }
  };

  const startTimer = () => {
    timer = setTimeout(() => {
      setShowResendOtp(true);
    }, 60000);
  };

  const submit = async () => {
    if (!name) {
      setError(true);
      return;
    }
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Access-Control-Allow-Origin", "*");
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("userToken")}`
      );

      var raw = JSON.stringify({
        name,
        email,
      });

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch("https://api.washon.ae/profile", requestOptions)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else return false;
        })
        .then((result) => {
          if (!result) {
            alert("Something went wrong");
          } else {
            props.loggedIn();
            setError(false);
            handleClose();
          }
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.error(error);
    }
  };

  const signUp = async () => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Access-Control-Allow-Origin", "*");

      var raw = JSON.stringify({ phone_number: `971${phoneNumber}` });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch("https://api.washon.ae/user/login", requestOptions)
        .then((response) => {
          if (response.status === 200) {
            return response.json();
          } else return false;
        })
        .then((result) => {
          if (result) {
            localStorage.setItem("userId", result.data);
            setEntered(true);
            startTimer();
          } else {
            alert("Invalid Phone Number");
          }
        })
        .catch((error) => console.error("error", error));
    } catch (error) {
      console.error(error);
    }
  };

  const resendOTPCall = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({
      user_id: localStorage.getItem("userId"),
    });
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch("https://api.washon.ae/user/login/otp/resend", requestOptions)
      .then((response) => {
        setShowResendOtp(false);
        if (response.status === 200) {
          startTimer();
          return response.json();
        } else return false;
      })
      .catch((error) => {
        console.error("error", error);
      });
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {title}
        </DialogTitle>
        <DialogContent dividers>
          {!entered ? (
            <div>
              <h6 style={{ fontSize: "14px", color: "rgb(168, 176, 201)" }}>
                Your Phone Number
              </h6>
              <div className="phone-number-container">
                <span style={{ margin: "0 10px" }}>+971</span>
                <input
                  type="number"
                  className="phone-number"
                  placeholder="12  345  6789"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                ></input>
              </div>
              <div className="btn-login" onClick={signUp}>
                CONTINUE
              </div>
            </div>
          ) : !otpVerified ? (
            <div>
              <div
                style={{
                  height: "80px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div>
                  <OtpInput
                    inputStyle={{
                      width: "54px",
                      height: "50px",
                      margin: "0 1rem",
                      fontSize: "2rem",
                      borderRadius: 4,
                      border: "none",
                      backgroundColor: "rgb(94, 129, 244)",
                      color: "#fff",
                    }}
                    numInputs={4}
                    isDisabled={false}
                    onChange={handleOtpChange}
                    separator={<span>-</span>}
                    isInputNum={true}
                    shouldAutoFocus
                    value={otp}
                  />
                </div>
              </div>
              {!showResendOtp && (
                <div className="resend-btn-disabled">
                  {" "}
                  Button will enable in 60 seconds{" "}
                </div>
              )}
              {showResendOtp && <ResendButton resendOTPCall={resendOTPCall} />}
            </div>
          ) : (
            <div>
              <h6 style={{ fontSize: "14px", color: "rgb(168, 176, 201)" }}>
                Name *
              </h6>
              <div className="phone-number-container">
                <input
                  style={{ margin: "0 10px", width: "100%" }}
                  type="text"
                  className="phone-number"
                  placeholder="Enter your name"
                  onChange={(e) => setName(e.target.value)}
                ></input>
              </div>
              {errorName && (
                <span
                  style={{
                    fontSize: "10px",
                    color: "red",
                    marginBottom: "5px",
                  }}
                >
                  This field is required
                </span>
              )}
              <h6 style={{ fontSize: "14px", color: "rgb(168, 176, 201)" }}>
                Email
              </h6>
              <div className="phone-number-container">
                <input
                  style={{ margin: "0 10px", width: "100%" }}
                  type="text"
                  className="phone-number"
                  placeholder="Enter your email address"
                  onChange={(e) => setEmail(e.target.value)}
                ></input>
              </div>
              <div className="btn-login" onClick={submit}>
                SUBMIT
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}
