import React from "react";
import { connect } from "react-redux";
import { setSlotsData, setProfileData } from "../actions";
import logo from "../images/washon_purple_logo.png";

class PromoCode extends React.Component {
  state = {
    address: [],
  };

  componentDidMount() {
    document.body.style.backgroundColor = "rgb(249, 249, 251)";

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("userToken")}`
    );
    let requestOptions2 = {
      // credentials: 'include',
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch("https://api.washon.ae/profile/get", requestOptions2)
      .then((response) => {
        if (response.status === 200) return response.json();
        else return false;
      })
      .then((result) => {
        if (result) {
          this.props.setProfileData(result.data);
        }
      })
      .catch((error) => console.error(error));
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.profileData !== nextProps.profileData) {
      const { ref_code } = nextProps.profileData;
      this.setState({
        ref_code,
      });
    }
  }

  copyToClipboard = () => {
    var textField = document.createElement("textarea");
    textField.innerText = this.props.profileData.ref_code;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();
    this.setState({ copied: true });
    setTimeout(() => this.setState({ copied: false }), 2000);
  };

  render() {
    return (
      <div className="profile-container">
        <h3>Hello {this.props.profileData.name || "User"}</h3>
        <div className="sub-container">
          <div className="promo-code-container">
            <h3>Promo Code</h3>
            <div
              style={{
                width: "100%",
                height: "1px",
                background: "rgb(236, 237, 242)",
                marginTop: "14px",
              }}
            ></div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <img alt="WashOn Laundry: Logo" src={logo} style={{ height: "100px", margin: "30px 0" }} />
              <div className="promo-code-box">
                {this.props.profileData.ref_code}
              </div>
              <div style={{ width: "350px", textAlign: "center" }}>
                <p
                  style={{
                    color: "rgb(143, 152, 179)",
                    marginBottom: "10px",
                    marginTop: "20px",
                    padding: window.innerWidth > 768 ? "" : "0 40px",
                  }}
                >
                  Get 50% discount on your next laundry when you refer your
                  friend.
                </p>
                <p style={{ color: "rgb(143, 152, 179)" }}>
                  Your friend will also get 50% discount.
                </p>
                <div
                  className="book-btn2 book-btn3"
                  onClick={this.copyToClipboard}
                >
                  COPY REFER LINK
                </div>
                {this.state.copied && (
                  <div style={{ color: "green" }}>Copied!</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    slotsData: state.slotsData,
    profileData: state.profileData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSlotsData: (data) => {
      dispatch(setSlotsData(data));
    },
    setProfileData: (data) => {
      dispatch(setProfileData(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PromoCode);
