import React from "react";
import "./App.css";

import "bootstrap/dist/css/bootstrap.min.css";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";
import { Route, Switch, Link } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";

import logoWhite from "./images/logo-white.png";
import profile from "./images/user.png";
import LoginDialog from "./components/LoginDialog";
import whatsAppLogo from "./images/call_logo_90.png";
import personBlue from "./images/person_icon_blue.png";
import personGrey from "./images/person_icon_grey.png";
import orderBlue from "./images/order_icon_blue.png";
import orderGrey from "./images/order_icon_grey.png";
import priceBlue from "./images/pricing_icon_blue.png";
import priceGrey from "./images/pricing_icon_grey.png";
import promoBlue from "./images/promo_icon_blue.png";
import promoGrey from "./images/promo_icon_grey.png";
import paymentBlue from "./images/payment_icon_blue.png";
import paymentGrey from "./images/payment_icon_grey.png";
import logoutBlue from "./images/logout_icon_blue.png";
import logoutGrey from "./images/logout_icon_grey.png";
import ArticleDetails from "./routes/ArticleDetails";
import Home from "./routes/Home";
import PricingPage from "./routes/PricingPage";
import BookOrder from "./routes/BookOrder";
import Faq from "./routes/Faq";
import Profile from "./routes/Profile";
import OrdersHistory from "./routes/OrdersHistory";
import PromoCode from "./routes/PromoCode";
import Privacy from "./routes/Privacy";
import TnC from "./routes/TnC";
import SavedCards from "./routes/SavedCards";
import Footer from "./components/Footer";
import Blogs from "./routes/Blogs";
import FeedbackForm from "./routes/FeedbackForm";
import ServicePage from "./routes/ServicePage";
import ServiceDetails from "./routes/ServicesDetails";
import LocationDetails from "./routes/LocationDetails";
import LocationManager from "./routes/LocationManager"

import ErrorPage from "./routes/ErrorPage";


const StyledMenuItem = withStyles((theme) => ({
  root: {
    height: 48,
    fontSize: "16px",
    width: 180,
    "&:focus": {
      backgroundColor: "rgb(238, 242, 255)",
    },
  },
}))(MenuItem);

export default connect(
  (state) => ({}),
  (dispatch) =>
    bindActionCreators(
      {
        push,
      },
      dispatch
    )
)(
  class App extends React.Component {
    state = {
      selectedService: null,
      openDialog: false,
      showDropdown: false,
      page: "home",
      bookOrder: false,
      bookOrder2: false,
      bookOrderAndLoggedOut: false,
    };

    componentDidMount() {
      this.params = new URLSearchParams(
        window.location.href.split("?")
          ? window.location.href.split("?")[1]
          : ""
      );

      if (this.params.has("page")) {
        this.setState({
          page: this.params.get("page"),
        });
      }
    }

    handleServiceSelection = (type) => {
      this.setState({
        selectedService: type,
      });
    };
    handleCloseDialog = () => {
      this.setState({
        openDialog: false,
      });
    };

    loggedIn = () => {
      this.setState({
        isLoggedIn: true,
        page: "booking",
      });
    };

    logout = () => {
      this.handleClose();
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Accept", "application/json");
      myHeaders.append(
        "Authorization",
        `Bearer ${localStorage.getItem("userToken")}`
      );
      var requestOptions = {
        // credentials: 'include',
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      fetch("https://api.washon.ae/api/user/logout", requestOptions)
        .then((response) => response.json())
        .then(() => {
          console.log("logged out successfully");
          localStorage.removeItem("userId");
          localStorage.removeItem("userToken");
          this.setState({ isLoggedIn: false, b: false, page: "home" });
        })
        .catch((error) => console.error(error));
    };

  setBookOrder = () => {
      if (!this.state.bookOrder && !localStorage.getItem("userToken")) {
        this.setState({
          openDialog: true,
          bookOrder2: true,
        });
      } else
        this.setState((prevState) => {
          if (prevState.page !== "booking")
            return {
              bookOrder: !prevState.bookOrder,
              page: "booking",
            };
          else
            return {
              bookOrder: !prevState.bookOrder,
              page: "home",
            };
        });
    };

    handleClose = () => {
      this.setState({
        anchorEl: null,
      });
    };

    setPage = (page) => {
      this.setState({
        page,
      });
    };

    render() {
      const { anchorEl, page } = this.state;
      const { handleClose, logout } = this;
      return (
        <div>
          <div>
            <div className="header-sec">
              <Link to="/" style={{ textDecoration: "none" }}>
                <img
                  alt="WashOn App logo white"
                  onClick={() => this.setState({ page: "home" })}
                  style={{
                    padding:
                      window.innerWidth > 768 ? "15px 50px" : "15px 15px",
                    cursor: "pointer",
                  }}
                  src={logoWhite}
                />
              </Link>
              <div
                style={{
                  float: "right",
                  display: "flex",
                  alignItems: "center",
                  height: "88px",
                }}
              >
                {window.innerWidth > 768 && (
                  <div
                    style={{
                      marginRight: "100px",
                      display: "flex",
                      alignItems: "baseline",
                    }}
                  >
                    <Link to="/pricing" style={{ textDecoration: "none" }}>
                      <div
                        className={`header-tabs ${
                          this.state.page === "pricing" ? "tab-active" : ""
                        }`}
                        onClick={() => this.setState({ page: "pricing" })}
                        style={{ marginRight: "25px" }}
                      >
                        Pricing
                      </div>
                    </Link>
                    <Link to="/services" style={{ textDecoration: "none" }}>
                      <div
                        className={`header-tabs ${
                          this.state.page === "pricing" ? "tab-active" : ""
                        }`}
                        onClick={() => this.setState({ page: "services" })}
                        style={{ marginRight: "25px" }}
                      >
                        Services
                      </div>
                    </Link>
                    <Link to="/blogs" style={{ textDecoration: "none" }}>
                      <div
                        className={`header-tabs ${
                          this.state.page === "blogs" ? "tab-active" : ""
                        }`}
                        onClick={() => this.setState({ page: "blogs" })}
                        style={{ marginRight: "25px" }}
                      >
                        Blogs
                      </div>
                    </Link>
                    <Link to="/faq" style={{ textDecoration: "none" }}>
                      <div
                        className={`header-tabs ${
                          this.state.page === "faq" ? "tab-active" : ""
                        }`}
                        style={{ marginRight: "25px" }}
                        onClick={() => this.setState({ page: "faq" })}
                      >
                        FAQ
                      </div>
                    </Link>
                  </div>
                )}
                <a
                  href="https://api.whatsapp.com/send?phone=971506856502"
                  style={{ textDecoration: "none" }}
                >
                  <div>
                    <img
                      alt=""
                      style={{
                        height: "20px",
                        weight: "20px",
                        marginRight: "15px",
                        marginLeft: "50px",
                      }}
                      src={whatsAppLogo}
                    />
                    <div
                      style={{
                        color: "white",
                        marginRight: "15px",
                        display: "flex",
                      }}
                    >
                      +971-506856502
                    </div>
                  </div>
                </a>
                {localStorage.getItem("userToken") || this.state.isLoggedIn ? (
                  <div style={{ marginRight: "40px" }}>
                    <img
                      alt=""
                      src={profile}
                      style={{ height: "40px", weight: "40px" }}
                      onClick={(e) =>
                        this.setState({
                          showDropdown: true,
                          anchorEl: e.currentTarget,
                        })
                      }
                    />
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                      getContentAnchorEl={null}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <Link to="/profile" style={{ textDecoration: "none" }}>
                        <StyledMenuItem>
                          <div
                            style={{
                              background:
                                page === "profile" ? "rgb(238, 242, 255)" : "",
                              padding: "8px 12px",
                              width: "100%",
                            }}
                            onClick={() => this.setState({ page: "profile" })}
                          >
                            <img
                              alt=""
                              style={{ height: "18px", marginRight: "14px" }}
                              src={page === "profile" ? personBlue : personGrey}
                            />
                            <span
                              style={{
                                color:
                                  page === "profile"
                                    ? "rgb(94, 129, 244)"
                                    : "rgb(30, 30, 93)",
                              }}
                            >
                              Profile
                            </span>
                          </div>
                        </StyledMenuItem>
                      </Link>
                      <Link to="/orders" style={{ textDecoration: "none" }}>
                        <StyledMenuItem>
                          <div
                            style={{
                              background:
                                page === "myOrders" ? "rgb(238, 242, 255)" : "",
                              padding: "8px 12px",
                              width: "100%",
                            }}
                            onClick={() => this.setState({ page: "myOrders" })}
                          >
                            <img
                              alt=""
                              style={{ height: "18px", marginRight: "14px" }}
                              src={page === "myOrders" ? orderBlue : orderGrey}
                            />
                            <span
                              style={{
                                color:
                                  page === "myOrders"
                                    ? "rgb(94, 129, 244)"
                                    : "rgb(30, 30, 93)",
                              }}
                            >
                              My Orders
                            </span>
                          </div>
                        </StyledMenuItem>
                      </Link>
                      <Link to="/savedcards" style={{ textDecoration: "none" }}>
                        <StyledMenuItem>
                          <div
                            style={{
                              background:
                                page === "payment" ? "rgb(238, 242, 255)" : "",
                              padding: "8px 12px",
                              width: "100%",
                            }}
                            onClick={() =>
                              this.setState({ page: "savedcards" })
                            }
                          >
                            <img
                              alt=""
                              style={{ height: "18px", marginRight: "14px" }}
                              src={
                                page === "payment" ? paymentBlue : paymentGrey
                              }
                            />
                            <span
                              style={{
                                color:
                                  page === "payment"
                                    ? "rgb(94, 129, 244)"
                                    : "rgb(30, 30, 93)",
                              }}
                            >
                              Payments
                            </span>
                          </div>
                        </StyledMenuItem>
                      </Link>
                      <Link to="/pricing" style={{ textDecoration: "none" }}>
                        <StyledMenuItem>
                          <div
                            style={{
                              background:
                                page === "pricing" ? "rgb(238, 242, 255)" : "",
                              padding: "8px 12px",
                              width: "100%",
                            }}
                            onClick={() => this.setState({ page: "pricing" })}
                          >
                            <img
                              alt=""
                              style={{ height: "18px", marginRight: "14px" }}
                              src={page === "pricing" ? priceBlue : priceGrey}
                            />
                            <span
                              style={{
                                color:
                                  page === "pricing"
                                    ? "rgb(94, 129, 244)"
                                    : "rgb(30, 30, 93)",
                              }}
                            >
                              Pricing
                            </span>
                          </div>
                        </StyledMenuItem>
                      </Link>
                      <Link to="/promocode" style={{ textDecoration: "none" }}>
                        <StyledMenuItem>
                          <div
                            style={{
                              background:
                                page === "promo" ? "rgb(238, 242, 255)" : "",
                              padding: "8px 12px",
                              width: "100%",
                            }}
                            onClick={() => this.setState({ page: "promo" })}
                          >
                            <img
                              alt=""
                              style={{ height: "18px", marginRight: "14px" }}
                              src={page === "promo" ? promoBlue : promoGrey}
                            />
                            <span
                              style={{
                                color:
                                  page === "promo"
                                    ? "rgb(94, 129, 244)"
                                    : "rgb(30, 30, 93)",
                              }}
                            >
                              Promo Code
                            </span>
                          </div>
                        </StyledMenuItem>
                      </Link>
                      <Link to="/" style={{ textDecoration: "none" }}>
                        <StyledMenuItem onClick={logout}>
                          <div
                            style={{
                              background:
                                page === "logout" ? "rgb(238, 242, 255)" : "",
                              padding: "8px 12px",
                              width: "100%",
                            }}
                            onClick={() => this.setState({ page: "logout" })}
                          >
                            <img
                              alt=""
                              style={{ height: "18px", marginRight: "14px" }}
                              src={page === "logout" ? logoutBlue : logoutGrey}
                            />
                            <span
                              style={{
                                color:
                                  page === "logout"
                                    ? "rgb(94, 129, 244)"
                                    : "rgb(30, 30, 93)",
                              }}
                            >
                              Logout
                            </span>
                          </div>
                        </StyledMenuItem>
                      </Link>
                    </Menu>
                  </div>
                ) : (
                  <div
                    className="book-btn2"
                    style={{
                      width: window.innerWidth > 768 ? "116px" : "85px",
                      float: "right",
                      marginRight: "20px",
                      marginTop: 12,
                    }}
                    onClick={() => this.setState({ openDialog: true })}
                  >
                    LOGIN
                  </div>
                )}
              </div>
            </div>
            <Switch>
              <Route exact path="/">
                <Home setBookOrder={this.setBookOrder} />
              </Route>
              <Route exact path="/home">
                <Home setBookOrder={this.setBookOrder} />
              </Route>
              <Route exact path="/homepage">
                <Home setBookOrder={this.setBookOrder} />
              </Route>
              <Route path="/pricing">
                <PricingPage setBookOrder={this.setBookOrder} />
              </Route>
              <Route path="/booking">
                <BookOrder setBookOrder={this.setBookOrder} />
              </Route>
              <Route path="/faq">
                <Faq />
              </Route>
              <Route path="/profile">
                <Profile />
              </Route>
              <Route path="/orders">
                <OrdersHistory />
              </Route>
              <Route path="/promocode">
                <PromoCode />
              </Route>
              <Route path="/feedback">
                <FeedbackForm />
              </Route>
              <Route path="/privacypolicy">
                <Privacy />
              </Route>
              <Route path="/tandc">
                <TnC />
              </Route>
              <Route path="/savedcards">
                <SavedCards />
              </Route>
              <Route exact path="/blogs">
                <Blogs />
              </Route>
              <Route path="/blogs/:slug">
                <ArticleDetails />
              </Route>
              <Route exact path="/services">
                <ServiceDetails />
              </Route>
              <Route exact path="/locations">
                <LocationDetails />
              </Route>
              <Route path="/services/:slug">
                <ServicePage />
              </Route>
              <Route path="/locations/:slug">
              <LocationManager setBookOrder={this.setBookOrder} />
             </Route>
              <Route path="/404" >
                <ErrorPage />
              </Route>
              <Route path="/:slug" >
                <ErrorPage />
              </Route>
            </Switch>
          </div>
          <LoginDialog
            open={this.state.openDialog}
            handleCloseDialog={this.handleCloseDialog}
            loggedIn={this.loggedIn}
            bookOrder={this.state.bookOrder2}
          />
          {this.state.page !== "booking" && <Footer setPage={this.setPage} />}
        </div>
      );
    }
  }
);
