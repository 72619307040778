import React, { useCallback, useEffect, useState } from "react";
import { Button, CircularProgress, TextField, styled } from "@material-ui/core";
import { Star } from "@material-ui/icons";
import { Rating } from "@material-ui/lab";
import { useLocation } from "react-router-dom";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "rgb(94, 129, 244)",
  },
  "& .MuiRating-iconHover": {
    color: "rgb(94, 129, 244)",
  },
});

const FeedbackForm = () => {
  const [rating, setRating] = useState(1);
  const [reviewText, setReviewText] = useState("");
  const [reviewSubmitted, setReviewSubmitted] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [tokenValidated, setTokenValidated] = useState(null);
  const [validatingToken, setValidatingToken] = useState(false);
  const [submittingFeedback, setSubmittingFeedback] = useState(false);

  const location = useLocation();
  const token = location.search.substring(location.search.indexOf("=") + 1);

  const validateFeedbackToken = useCallback(async () => {
    setValidatingToken(true);
    const response = await fetch(
      "https://api.washon.ae/booking/feedback/validtoken",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: token,
        }),
      }
    );

    const result = await response.json();

    if (
      result.data &&
      result.data.order_id !== null &&
      result.data.order_id !== 0
    ) {
      setTokenValidated(true);
      setReviewSubmitted(result.data.submitted);
      setOrderId(result.data.order_id);
    } else {
      setTokenValidated(false);
    }
    setValidatingToken(false);
  }, [token]);

  const sendFeedback = async () => {
    setSubmittingFeedback(true);
    const response = await fetch(
      "https://api.washon.ae/booking/feedback/submit",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: token,
          rating: rating,
          comment: reviewText,
        }),
      }
    );
    const result = await response.json();
    if (result.data.success) {
      setReviewSubmitted(true);
    }
    setSubmittingFeedback(false);
  };

  useEffect(() => {
    (async () => {
      await validateFeedbackToken();
    })();
  }, [validateFeedbackToken]);

  if (validatingToken) {
    return (
      <div
        style={{
          height: "500px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  if (!validatingToken && tokenValidated === false) {
    return (
      <div style={{ height: "550px" }}>
        <div className="sub-container">
          <div className="promo-code-container">
            <h2 style={{ color: "red" }}>Invalid Token!</h2>
            <h4>
              Look like link has been expired. Please contact support@washon.ae for feedback.
            </h4>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div style={{ height: "550px" }}>
        <div className="sub-container">
          <div className="promo-code-container">
            {!reviewSubmitted ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyItems: "center",
                  alignItems: "center",
                  color: "rgb(30, 30, 93)",
                }}
              >
                <h1>
                  Thanks for your Order ID#{orderId}. Please take a moment to rate your experience with us.
                </h1>
                <StyledRating
                  style={{ margin: "15px 0" }}
                  icon={
                    <Star
                      style={{ width: "50px", height: "50px" }}
                      fontSize="inherit"
                    />
                  }
                  value={rating}
                  onChange={(event, newValue) => {
                    setRating(newValue);
                  }}
                  size="large"
                />
                <TextField
                  color="primary"
                  fullWidth
                  variant="outlined"
                  multiline
                  minRows={3}
                  maxRows={7}
                  placeholder="Provide your feedback with us..."
                  value={reviewText}
                  style={{ fontSize: "20px", margin: "20px 0" }}
                  InputProps={{
                    style: { fontSize: 16, color: "rgb(30, 30, 93)" },
                  }}
                  InputLabelProps={{
                    style: { fontSize: 12, color: "rgb(168, 176, 201)" },
                  }}
                  onChange={(e) => setReviewText(e.target.value)}
                />
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  style={{ margin: "30px 0" }}
                  onClick={sendFeedback}
                >
                  {submittingFeedback ? (
                    <CircularProgress color="white" size="3rem" />
                  ) : (
                    "Submit"
                  )}
                </Button>
              </div>
            ) : (
              <div>
                <h2 style={{ color: "rgb(30, 30, 93)" }}>
                Thanks for your order with us. Your feedback has been submitted.
                </h2>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
};

export default FeedbackForm;
