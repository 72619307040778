import React from "react";
import { connect } from "react-redux";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import DateTime from "../components/DateTimeContainer";
import Address from "../components/Address";
import Payment from "../components/Payment";
import orderConfirmTick from "../images/order-confirmation-tick.png";

import { setSlotsData, setProfileData } from "../actions";
import { Link } from "react-router-dom";

const useStyles = {
  root: {
    width: "100%",
  },
  button: {
    marginRight: "2px",
  },
  backButton: {
    marginRight: "2px",
  },
  completed: {
    display: "inline-block",
  },
  instructions: {
    marginTop: "2px",
    marginBottom: "2px",
  },
};

class BookOrder extends React.Component {
  state = {
    activeStep: 0,
    completed: new Set(),
    skipped: new Set(),
    steps: ["Date & Time", "Address", "Payment"],
    pickUpDate: 0,
    pickUpTime: 0,
    dropOffTime: 0,
    dropOffDate: 0,
    center: {
      lat: 25.2048,
      lng: 55.2708,
    },
    paymentMode: "",
    orderId: null,
    activeStepName: "Date & Time",
  };

  componentDidMount() {
    document.body.style.backgroundColor = "rgb(249, 249, 251)";
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("userToken")}`
    );
    var requestOptions = {
      // credentials: 'include',
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch("https://api.washon.ae/booking/slots", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        this.props.setSlotsData(result);
      })
      .catch((error) => console.error("error", error));

    navigator.geolocation.getCurrentPosition((position) => {
      this.setState({
        center: {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        },
      });
    });
    let requestOptions2 = {
      // credentials: 'include',
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch("https://api.washon.ae/profile/get", requestOptions2)
      .then((response) => {
        if (response.status === 200) return response.json();
        else return false;
      })
      .then((result) => {
        if (result) {
          this.props.setProfileData(result.data);
        }
      })
      .catch((error) => console.error("error", error));
  }

  setAddress = (
    markerPosition,
    flatNo,
    address,
    city,
    placeId,
    locationObj,
    directions
  ) => {
    this.setState({
      markerPosition,
      flatNo,
      address,
      city,
      placeId,
      locationObj,
      directions,
      paymentMode: "Cash",
    });
  };

  totalSteps = () => {
    return this.state.steps.length;
  };

  isStepOptional = (step) => {
    return step === 1;
  };

  skippedSteps = () => {
    return this.state.skipped.size;
  };

  completedSteps = () => {
    return this.state.completed.size;
  };

  isStepComplete = (step) => {
    return this.state.completed.has(step);
  };

  allStepsCompleted = () => {
    return this.completedSteps() === this.totalSteps() - this.skippedSteps();
  };

  handleStep = (step) => {
    this.setState({
      activeStep: step,
    });
  };

  isStepSkipped = (step) => {
    return this.state.skipped.has(step);
  };

  isLastStep = () => {
    return this.state.activeStep === this.totalSteps() - 1;
  };

  handleNext = () => {
    const newCompleted = new Set(this.state.completed);
    newCompleted.add(this.state.activeStep);

    this.setState(
      {
        completed: newCompleted,
        activeStepName: this.state.steps[this.state.activeStep + 1],
      },
      () => {
        if (!this.allStepsCompleted()) {
          // console.log("all steps not completed");
          const newActiveStep =
            this.isLastStep() && !this.allStepsCompleted()
              ? // It's the last step, but not all steps have been completed
                // find the first step that has been completed
                this.state.steps.findIndex(
                  (step, i) => !this.state.completed.has(i)
                )
              : this.state.activeStep + 1;

          this.setState({
            activeStep: newActiveStep,
          });
        }

        if (this.allStepsCompleted()) {
          if (!this.state.timeSlot) {
            alert("Timeslot not selected");
            return;
          }

          let bookingData = {
            pickup_time: this.state.timeSlot.time_string,
            dropoff_time: this.state.deliveryTimeSlot.time_string,
            location: this.state.locationObj,
            booking_price: {
              mode: this.state.paymentMode,
              user_payment_id:
                this.state.paymentMode === "Cash"
                  ? null
                  : this.state.paymentDetails.id,
            },
            instruction: this.state.directions ? this.state.directions : "",
          };
          if (this.state.promoCode) {
            bookingData.booking_price.promo_code = this.state.promoCode;
          }

          var raw = JSON.stringify(bookingData);

          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          myHeaders.append("Accept", "application/json");
          myHeaders.append(
            "Authorization",
            `Bearer ${localStorage.getItem("userToken")}`
          );
          var requestOptions = {
            method: "POST",
            headers: myHeaders,
            redirect: "follow",
            body: raw,
          };
          fetch("https://api.washon.ae/booking/create", requestOptions)
            .then((response) => {
              if (response.status === 200) {
                return response.json();
              } else return false;
            })
            .then((result) => {
              if (result) {
                this.setState({
                  orderConfirmed: true,
                  orderId: result.data.id,
                });
              }
            })
            .catch((error) => console.error("error", error));

          navigator.geolocation.getCurrentPosition((position) => {
            this.setState({
              center: {
                lat: position.coords.latitude,
                lng: position.coords.longitude,
              },
            });
          });
        }
      }
    );
  };

  setSlotValue = (type, value, timeSlot, deliveryTimeSlot, deliverySlots) => {
    this.setState({
      [type]: value,
    });

    if (timeSlot) {
      this.setState({
        timeSlot,
      });
    }

    if (deliveryTimeSlot) {
      this.setState({
        deliveryTimeSlot,
        deliverySlots,
      });
    }
  };
  setPaymentMode = (paymentType, paymentDetails, promoCode) => {
    this.setState({
      paymentMode: paymentType,
      paymentDetails,
      promoCode,
    });
  };
  render() {
    const classes = useStyles;
    return (
      <div>
        {!this.state.orderConfirmed ? (
          <div className="booking-container">
            <h3>{this.state.activeStepName}</h3>
            <div style={classes.root} className="status-check">
              <Stepper
                alternativeLabel
                nonLinear
                activeStep={this.state.activeStep}
              >
                {this.state.steps.map((label, index) => {
                  const stepProps = {};
                  const buttonProps = {};
                  if (this.isStepSkipped(index)) {
                    stepProps.completed = false;
                  }
                  return (
                    <Step key={label} {...stepProps}>
                      <StepButton
                        onClick={() => this.handleStep(index)}
                        completed={this.isStepComplete(index)}
                        {...buttonProps}
                      >
                        {label}
                      </StepButton>
                    </Step>
                  );
                })}
              </Stepper>
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
              {this.state.activeStep === 0 ? (
                <DateTime
                  setValue={this.setSlotValue}
                  data={this.props.slotsData ? this.props.slotsData.data : []}
                  activeStep={this.state.activeStep}
                  handleNext={this.handleNext}
                  steps={this.state.steps}
                />
              ) : this.state.activeStep === 1 ? (
                <Address
                  activeStep={this.state.activeStep}
                  handleNext={this.handleNext}
                  steps={this.state.steps}
                  google={this.props.google}
                  center={this.state.center}
                  height="300px"
                  setAddress={this.setAddress}
                  profileData={this.props.profileData}
                  zoom={15}
                />
              ) : (
                <Payment
                  activeStep={this.state.activeStep}
                  handleNext={this.handleNext}
                  steps={this.state.steps}
                  google={this.props.google}
                  setPaymentMode={this.setPaymentMode}
                />
              )}

              {window.innerWidth > 768 && (
                <div className="booking-details">
                  <h4>Service Details</h4>
                  <hr />
                  <div className="booking-details-subcontainer">
                    <span className="left-span">City</span>
                    <span className="right-span">Dubai</span>
                  </div>
                  <hr />
                  <div className="booking-details-subcontainer">
                    <span className="left-span">Pickup</span>
                    <span className="right-span">
                      {this.props.slotsData.data
                        ? this.props.slotsData.data[this.state.pickUpDate].day
                        : ""}
                      ,{" "}
                      {this.props.slotsData.data
                        ? this.props.slotsData.data[this.state.pickUpDate].date
                        : ""}
                    </span>
                  </div>
                  <div className="booking-details-subcontainer">
                    <span className="left-span">Drop Off</span>
                    <span className="right-span">
                      {this.state.deliverySlots
                        ? this.state.deliverySlots[this.state.dropOffDate].day
                        : ""}
                      ,{" "}
                      {this.state.deliverySlots
                        ? this.state.deliverySlots[this.state.dropOffDate].date
                        : ""}
                    </span>
                  </div>
                  <hr />
                  <div className="booking-details-subcontainer">
                    <span className="left-span">Address</span>
                    <span
                      className="right-span"
                      style={{
                        width: "141px",
                        textAlign: "right",
                        fontSize: "16px",
                      }}
                    >
                      {this.state.flatNo}
                      <br />
                      <span
                        style={{
                          color: "rgb(143, 146, 169)",
                          fontSize: "14px",
                        }}
                      >
                        {this.state.address}
                      </span>
                    </span>
                  </div>
                  <hr />
                  <div className="booking-details-subcontainer">
                    <span className="left-span">Payment</span>
                    <span className="right-span">
                      {this.state.paymentMode === "CreditCard"
                        ? `Credit Card....${this.state.paymentDetails.last4}`
                        : this.state.paymentMode}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div
            className="booking-container"
            style={
              window.innerWidth > 768
                ? {
                    marginTop: "0px",
                    position: "absolute",
                    top: "54%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }
                : { margin: "16px" }
            }
          >
            <img
              alt="success"
              src={orderConfirmTick}
              style={{ height: "80px" }}
            />
            <div style={{ color: "rgb(94, 129, 244)", fontSize: "20px" }}>
              Your Order {"#" + this.state.orderId} is Confirmed
            </div>
            <div
              className="booking-details"
              style={{
                width: window.innerWidth > 768 ? "400px" : "100%",
                margin: "25px 0",
              }}
            >
              <h4>Service Details</h4>
              <hr />
              <div className="booking-details-subcontainer">
                <span className="left-span">City</span>
                <span className="right-span">Dubai</span>
              </div>
              <hr />
              <div className="booking-details-subcontainer">
                <span className="left-span">Pickup</span>
                <span className="right-span">
                  {this.props.slotsData.data
                    ? this.props.slotsData.data[this.state.pickUpDate].day
                    : ""}
                  ,{" "}
                  {this.props.slotsData.data
                    ? this.props.slotsData.data[this.state.pickUpDate].date
                    : ""}
                </span>
              </div>
              <div className="booking-details-subcontainer">
                <span className="left-span">Drop Off</span>
                <span className="right-span">
                  {this.state.deliverySlots
                    ? this.state.deliverySlots[this.state.dropOffDate].day
                    : ""}
                  ,{" "}
                  {this.state.deliverySlots
                    ? this.state.deliverySlots[this.state.dropOffDate].date
                    : ""}
                </span>
              </div>
              <hr />
              <div className="booking-details-subcontainer">
                <span className="left-span">Address</span>
                <span
                  className="right-span"
                  style={{
                    width: "141px",
                    textAlign: "right",
                    fontSize: "16px",
                  }}
                >
                  {this.state.flatNo}
                  <br />
                  <span
                    style={{ color: "rgb(143, 146, 169)", fontSize: "14px" }}
                  >
                    {this.state.address}
                  </span>
                </span>
              </div>
              <hr />
              <div className="booking-details-subcontainer">
                <span className="left-span">Payment</span>
                <span className="right-span">
                  {this.state.paymentMode === "CreditCard"
                    ? "Credit Card"
                    : this.state.paymentMode}
                </span>
              </div>
            </div>
            <Link to="/">
              <div className="book-btn2" onClick={this.props.setBookOrder}>
                Go Back
              </div>
            </Link>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    slotsData: state.slotsData,
    profileData: state.profileData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setSlotsData: (data) => {
      dispatch(setSlotsData(data));
    },
    setProfileData: (data) => {
      dispatch(setProfileData(data));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookOrder);
