import React from "react";
import appleIcon from "../images/apple-icon-image.png";
import androidIcon from "../images/android-icon-image.png";
import logoWhite from "../images/logo-white.png";
import facebookLogo from "../images/facebook-logo.png";
import whatsappLogo from "../images/whatsapp-logo.png";
import FloatingButton from "./FloatingButton";
import { Link } from "react-router-dom";

const Footer = (props) => {
  return (
    <div>
      <div
        style={{
          position: "fixed",
          right: 50,
          bottom: 50,
          display: "flex",
        }}
      >
        <FloatingButton
          href="https://api.whatsapp.com/send?phone=971506856502"
          icon={whatsappLogo}
          title="Whatsapp"
        />
      </div>
      <div className="footer">
        <div className="footer-container">
          <div>
            <img
              alt=""
              src={logoWhite}
              style={window.innerWidth < 768 ? { height: "50px" } : {}}
            />
          </div>
          <div>
            <span>INFORMATION</span>
            <ul>
              <li onClick={() => props.setPage("pricing")}>
                <Link to="/pricing" style={{ textDecorationStyle: "none" }}>
                  PRICING
                </Link>
              </li>
              <li onClick={() => props.setPage("privacy")}>
                <Link
                  to="/privacypolicy"
                  style={{ textDecorationStyle: "none" }}
                >
                  PRIVACY POLICY
                </Link>
              </li>
              <li onClick={() => props.setPage("tnc")}>
                <Link to="/tandc" style={{ textDecorationStyle: "none" }}>
                  TERMS & CONDITIONS
                </Link>
              </li>
              <li onClick={() => props.setPage("faq")}>
                <Link to="/faq" style={{ textDecorationStyle: "none" }}>
                  FAQ
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <span>INFORMATION</span>
            <ul>
              <li onClick={() => props.setPage("blog")}>
                <Link to="/blogs" style={{ textDecorationStyle: "none" }}>
                  BLOGS
                </Link>
              </li>
              <li onClick={() => props.setPage("blog")}>
                <Link to="/services" style={{ textDecorationStyle: "none" }}>
                  SERVICES
                </Link>
              </li>
              <li onClick={() => props.setPage("locations")}>
                <Link to="/locations" style={{ textDecorationStyle: "none" }}>
                  LOCATIONS
                </Link>
              </li>
            </ul>
          </div>
          <div>
          <a href="https://www.facebook.com/washon.ae/">
            <span>FOLLOW US</span>
            <img
              alt="facebook logo"
              style={{ paddingLeft: "10px" }}
              src={facebookLogo}
            />
            </a>
          </div>
          <div>
            <span>MOBILE APPS</span>
            <div className="mobile-apps">
            <a href="http://onelink.to/p3c5wk">
              <div className="avail-app-container">
                <img alt="apple_icon" src={appleIcon} />
                <div className="avail-app">
                  <span style={{ fontSize: "11px" }}>Available on the</span>
                  <span>App Store</span>
                </div>
              </div>
              </a>
              <a href="http://onelink.to/p3c5wk">
              <div className="avail-app-container">
                <img alt="apple_icon" src={androidIcon} />
                <div className="avail-app">
                  <span style={{ fontSize: "11px" }}>Available on the</span>
                  <span>Play Store</span>
                </div>
              </div>
              </a>
            </div>
          </div>
        </div>
        <div className="rights">
          2018-2022. <span>WashOn</span>. All Rights Reserved.
        </div>
      </div>
    </div>
  );
};

export default Footer;
