import React from "react";

import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  makeStyles,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  media: {
    height: 240,
  },
  card: {
    maxWidth: "100%",
    height: 400,
  },
  title: {
    fontWeight: 600,
    textDecoration: "none",
  },
}));

export default function Article(props) {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={props.image}
          title={props.title}
        />
        <CardContent>
          <Typography
            className={classes.title}
            gutterBottom
            variant="h5"
            component="h3"
          >
            {props.title}
          </Typography>
          <Typography variant="h6" color="textSecondary" component="p">
            {props.subtitle.substring(0, 150)}...
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions></CardActions>
    </Card>
  );
}
